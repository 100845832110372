import { useEffect, useState } from "react";

const TimerCountdown = (props: any) => {
  const {initialMinute = 1,initialSeconds = 59} = props;
  const [ minutes, setMinutes ] = useState(initialMinute);
  const [seconds, setSeconds ] =  useState(initialSeconds);

  useEffect(()=> {
  let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
          props.isTimerEnd(true)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } 
    }, 1000)
    return ()=> {
      clearInterval(myInterval);
    };
  });
  
  const onRestartTimer = () => {
    setMinutes(initialMinute)
    setSeconds(initialSeconds)
    props.isTimerEnd(false)
  };

  useEffect(() => {
    if (props.isRestartTimer) {
      onRestartTimer()
    }
  }, [props.isRestartTimer])

  return (
    <>
      { /* 
        minutes === 0 && seconds === 0 ? 
        (
          null
        )
        : 
        (
          <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1>
        )
      */ }
      <span> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
    </>
  )
}

export default TimerCountdown;