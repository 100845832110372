import { Button, Popover } from "antd";
import clsx from "clsx";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import moment from "moment";
import Pubnub from "pubnub";
import { usePubNub } from 'pubnub-react'
import { useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { mstAuth, mstGeneral } from "../../../../mobx"
import { MdRefresh } from "react-icons/md";

interface IProps {
  // users?: Array<[]>
  // chatList?: Array<[]>
  // isLoadingUsers?: Boolean
  // isLoadingChatList?: Boolean
  location?: any
  isDrawer?: Boolean
}

const TestChatPage = observer(({ isDrawer = false }: IProps) => {
  const history = useHistory()
  const [chatList, setChatList] = useState([])
  const [userList, setUserList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingChatList, setIsLoadingChatList] = useState(false)
  const [isLoadingUserList, setIsLoadingUserList] = useState(false)
  const [isNewChat, setIsNewChat] = useState(false)
  const [selectedUser, setSelectedUser] = useState<{ user_uuid?: string, name?: string, chat_uuid?: string, connectionStatus?: string }>({})
  const [channels, setChannels] = useState([])
  const [message, setMessage] = useState("")
  const [messageHistory, setMessageHistory] = useState<any>([])
  const bottomRef = useRef<HTMLDivElement>(null);
  const pubnub = usePubNub()
  // get user uuid from url params
  const location = useLocation()
  const urlQuery = new URLSearchParams(location.search)
  const uuidFromUrl = urlQuery.get('user')
  // console.log("check params urlQuery ====> ", urlQuery.get('user'))
  // console.log("check testchatpage getSnapshot(mstGeneral).active_users ===> ", getSnapshot(mstGeneral).active_users)

  // useEffect(() => {
  //   if (uuidFromUrl !== undefined) {
  //     mstAuth.fetchChatList()
  //     .then((response) => {
  //       const foundUser: any = response.find((x: any) => x.user_uuid === uuidFromUrl)
  //       onClickUser(foundUser, "old")
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  //   }
  // }, [uuidFromUrl])

  useEffect(() => {
    getUsers()
  }, [getSnapshot(mstGeneral).active_users, uuidFromUrl])

  const onRefreshList = () => {
    setIsLoading(true)
    getUsers()
  }

  const getUsers = () => {
    mstAuth.fetchChatList()
    .then((response) => {
      const arrayChatList: any = []
      response.map((user: any) => {
        arrayChatList.push({
          ...user,
          connectionStatus: getSnapshot(mstGeneral).active_users.find(x => x === user.user_uuid) ? "online" : "offline"
        })
      })
      mstAuth.fetchUserByRole({ role: "manager" })
      .then((userListResponse) => {
        const dataArray: any = []
        const getChatListUuids: any = response?.map((i: any) => i.user_uuid)
        console.log("check getchatlistuuids ===> ", getChatListUuids)
        userListResponse?.users.filter((x: any) => !getChatListUuids.includes(x.user_uuid)).filter((x: any) => x.user_uuid !== getSnapshot(mstAuth).userDetail.user_uuid).map((res: any, resIndex: number) => {
          dataArray.push({
            key: resIndex + 1,
            uuid: res?.user_uuid,
            user: { full_name: res?.fullname, username: res?.username, email: res?.email, profile_image: res?.profile_image },
            phone_number: res?.phone_number,
            country: { country_name: res?.country, country_flag: res?.country_flag },
            role: res?.role,
          })
        })
        setIsLoading(false)
        setUserList(dataArray)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
      setChatList(arrayChatList)
      if (uuidFromUrl !== undefined) {
        const foundUser: any = arrayChatList.find((x: any) => x.user_uuid === uuidFromUrl)
        onClickUser(foundUser, "old")
      }
    })
    .catch((error) => {
      console.log(error)
    }) 
  }

  const onSendChat = () => {
    const msjObj = { 
      message: message,
      recipient: {
        name: selectedUser?.name,
        uuid: selectedUser?.user_uuid
      },
      sender: {
        name: getSnapshot(mstAuth).userDetail.username,
        uuid: getSnapshot(mstAuth).userDetail.user_uuid
      },
      channel_uuid: `private.${selectedUser?.chat_uuid}`, 
      timestamp: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    }
    pubnub.publish({ channel: `private.${selectedUser.chat_uuid}`, message: msjObj, storeInHistory: true })
    mstAuth.notifyChat({ user_uuid: selectedUser?.user_uuid, body: message, title: getSnapshot(mstAuth).userDetail.username })
    setMessage("")
  }

  const onClickUser = (user: any, type: any) => {
    console.log("check ===> ", user)
    // const channelArray: any = [`private.${user.chat_uuid}`]
    // setSelectedUser(user)
    // getMessageHistory(channelArray[0])

    if (type === 'new') {
      history.push(`/admin/messages?user=${user.uuid}`)
      mstAuth.fetchOrCreateChannel({ recipient_id: user.uuid })
      .then((response) => {
        const channelArray: any = [`private.${response.channel_uuid}`]
        mstGeneral.updateSelectedChatUser(user)
        setSelectedUser(user)
        setChannels(channelArray)
        setIsNewChat(false)
        // pubnub.subscribe({ channels: channelArray });
        getMessageHistory(`private.${response.channel_uuid}`)
      })
      .catch((error) => {
        console.log(`${error}`)
      })
    } else {
      history.push(`/admin/messages?user=${user.user_uuid}`)
      mstAuth.fetchOrCreateChannel({ recipient_id: user.user_uuid })
      .then((response) => {
        const channelArray: any = [`private.${response.channel_uuid}`]
        console.log("check fetchOrCreateChannel onClickUser ===> ", response)
        mstGeneral.updateSelectedChatUser(user)
        setSelectedUser(user)
        setChannels(channelArray)
        // pubnub.subscribe({ channels: channelArray });
        getMessageHistory(`private.${response.channel_uuid}`)
      })
      .catch((error) => {
        console.log(`${error}`)
      })
    }
  }

  const getMessageHistory = (channel_uuid: any) => {
    const foundChannel: any = getSnapshot(mstGeneral).chat_details.find((x: any) => x.channelId === channel_uuid)
    // console.log("check getMessageHistory ===> ", foundChannel)
    if (foundChannel.messages !== undefined) {
      setMessageHistory(foundChannel.messages.map((i: any) => i.message))
    } else {
      setMessageHistory([])
    }
  }

  useEffect(() => {
    const ref: any = bottomRef.current
    if (ref) {
      ref.scrollIntoView({ behavior: 'auto' })
    }
  }, [messageHistory])

  const handleMessage = (event: any) => {
    const newMessageChannel: any = event.channel
    const currentChannel: any = `private.${getSnapshot(mstGeneral).selected_chat_user.chat_uuid}`
    console.log("check eventchannel and selected chat id ===> ", event, currentChannel)
    if (newMessageChannel === currentChannel) {
      const newMsg: any = event.message
      setMessageHistory((history: any) => [...history, newMsg]);
    }
  }

  useEffect(() => {
    pubnub.subscribe({
      channels
    })

    return () => {
      pubnub.unsubscribe({
        channels
      })
    }
  }, [channels])

  useEffect(() => {
    pubnub.addListener({
      message: handleMessage
    })

    return () => {
      pubnub.removeListener({
        message: handleMessage
      })
    }
  }, [pubnub])

  const priority: any = {
    online: 2,
    offline: 1
  };

  return (
    <div className='d-flex flex-column flex-lg-row'>
      <>
        <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
          <div className='card card-flush overflow-hidden'>
            <div className='card-header p-4' id='kt_chat_contacts_header'>
              <div className="w-100 d-flex justify-content-between align-items-center">
              {
                isNewChat ?
                (
                  <button className='btn btn-primary w-100' onClick={() => { setIsNewChat(false) }}>Back</button>
                )
                :
                (
                  <button className='btn btn-primary w-100' onClick={() => { setIsNewChat(true) }}>New Chat</button>
                )
              }
              <button className={`btn btn-secondary w-20 ms-4 p-3 ${isLoading ? 'disabled' : ''}`} onClick={() => { onRefreshList() }}><MdRefresh size={24} /></button>
              </div>
            </div>
            <div className='card-body p-0' id='kt_chat_contacts_body'>
              <div
                className='overflow-scroll pe-0 h-min-500px h-500px'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                data-kt-scroll-offset='0px'
              >
                {
                  isNewChat ?
                  (
                    <>
                    {
                      isLoading ?
                      (
                        <div className="d-flex w-100 h-150px justify-content-center align-items-center">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          {/* <div className="mt-4">Loading Chat</div> */}
                        </div>
                      )
                      :
                      (
                        <>
                        {
                          userList.sort((a: any, b: any) => priority[b.connectionStatus] - priority[a.connectionStatus]).length === 0 ?
                          (
                            <div className="d-flex w-100 h-150px justify-content-center align-items-center">
                              <div>No Users Found</div>
                            </div>
                          )
                          :
                          (
                            <>
                            {
                              userList.sort((a: any, b: any) => priority[b.connectionStatus] - priority[a.connectionStatus]).map((user: any, userIndex: Number) => {
                                return (
                                  <>
                                    <a onClick={() => { onClickUser(user, "new") }} className="cursor-pointer">
                                      <div className='d-flex flex-stack p-4'>
                                        <div className='d-flex align-items-center'>
                                          <div className='symbol symbol-45px symbol-circle'>
                                            <img src={`https://ui-avatars.com/api/?name=${user.user.full_name}&background=random`} />
                                          </div>

                                          <div className='ms-5'>
                                            <div className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                                              {user.user.full_name}
                                            </div>
                                            <div className='fw-bold text-gray-400'>{user.name}</div>
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                          {
                                            user.connectionStatus === "online" ?
                                            (
                                              <span className='fs-7 mb-1 text-success'>Online</span>
                                            )
                                            :
                                            (
                                              <span className='text-muted fs-7 mb-1'>Offline</span>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </a>
                                    <div className='separator separator-dashed d-none'></div>
                                  </>
                                )
                              })
                            }
                            </>
                          )
                        }
                        </>
                      )
                    }
                    </>
                  )
                  :
                  (
                    <>
                    {
                      isLoading ?
                      (
                        <div className="d-flex w-100 h-150px justify-content-center align-items-center">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          {/* <div className="mt-4">Loading Chat</div> */}
                        </div>
                      )
                      :
                      (
                        <>
                        {
                          chatList.sort((a: any, b: any) => priority[b.connectionStatus] - priority[a.connectionStatus]).length === 0 ?
                          (
                            <div className="d-flex w-100 h-150px justify-content-center align-items-center">
                              <div>No Chats Found</div>
                            </div>
                          )
                          :
                          (
                            <>
                            {
                              chatList.sort((a: any, b: any) => priority[b.connectionStatus] - priority[a.connectionStatus]).map((user: any, userIndex: Number) => {
                                const lastMessage: any = getSnapshot(mstGeneral).chat_details.find((x: any) => x.channelId === `private.${user.chat_uuid}`)?.messages[getSnapshot(mstGeneral).chat_details.find((x: any) => x.channelId === `private.${user.chat_uuid}`)?.messages.length - 1]?.message?.message
                                const unreadCount: any = getSnapshot(mstGeneral).chat_unread_counts.find((x: any) => x.channelId === `private.${user.chat_uuid}`)?.unreadCount

                                // console.log("check unreadCounts ===> ", getSnapshot(mstGeneral).chat_unread_counts)
                                // console.log("check getSnapshot(mstGeneral).chat_details ===> ", lastMessage)

                                return (
                                  <>
                                    <div>
                                      <div className={`d-flex flex-stack p-4 ${selectedUser.user_uuid === user.user_uuid ? "bg-gray-300" : ""}`}>
                                        <div className='d-flex align-items-center'>
                                          <a onClick={() => { onClickUser(user, "old") }} className="cursor-pointer">
                                            <div className='symbol symbol-45px symbol-circle'>
                                              <img src={`https://ui-avatars.com/api/?name=${user.name}&background=random`} />
                                            </div>
                                          </a>

                                          <div className='ms-5'>
                                            <div className='d-flex align-items-center'>
                                              <a onClick={() => { onClickUser(user, "old") }} className="cursor-pointer">
                                                <div className='fs-5 fw-bolder text-gray-900 text-hover-primary'>
                                                  {user.name}
                                                </div>
                                              </a>
                                              {/* 
                                                unreadCount > 0 ?
                                                (
                                                  <div className='fs-8 fw-bold h-20px w-20px bg-primary text-white ms-4 d-flex align-items-center justify-content-center rounded-circle'>
                                                    <div>{unreadCount}</div>
                                                  </div>
                                                )
                                                :
                                                (
                                                  null
                                                )
                                                */}
                                            </div>
                                            <div className='text-gray-400 mt-1'>{lastMessage}</div>
                                            <div className="mt-2">
                                              {
                                                user.teams.length > 0 && (
                                                  <div className="d-flex">
                                                    {
                                                      user.teams.slice(0,3).map((team: any) => {
                                                        return (
                                                          <Popover content={() => {
                                                            return (
                                                              <>
                                                                <div>
                                                                  {team.team_name}
                                                                </div>
                                                              </>
                                                            )
                                                          }}>
                                                            <div style={{ border: '0px solid #dfdfdf', backgroundColor: '#f5f5f5' }} className="cursor-pointer me-2 d-flex align-items-center justify-content-center h-30px w-30px rounded-circle">
                                                              <img src={team.team_logo} className="p-1" width="24px" height="" />
                                                            </div>
                                                          </Popover>
                                                        )
                                                      })
                                                    }
                                                    {
                                                      user.teams.length > 3 && (
                                                        <Popover content={() => {
                                                          return (
                                                            <div className="h-200px overflow-scroll">
                                                              {
                                                                user.teams.slice(4, user.teams.length - 3).map((team: any) => {
                                                                  return (
                                                                    <>
                                                                      <div className="d-flex align-items-center mb-3">
                                                                        <div style={{ border: '0px solid #dfdfdf', backgroundColor: '#f5f5f5' }} className="me-3 d-flex align-items-center justify-content-center h-30px w-30px rounded-circle">
                                                                          <img src={team.team_logo} className="p-1" width="24px" height="" />
                                                                        </div>
                                                                        <div>{team.team_name}</div>
                                                                      </div>
                                                                    </>
                                                                  )
                                                                })
                                                              }
                                                            </div>
                                                          )
                                                        }}>
                                                          <div style={{ border: '0px solid #dfdfdf', backgroundColor: '#f5f5f5' }} className="me-2 d-flex align-items-center justify-content-center h-30px w-30px rounded-circle cursor-pointer">
                                                            <div className="fs-8 fw-bold">{user.teams.length - 3}+</div>
                                                          </div>
                                                        </Popover>
                                                      )
                                                    }
                                                  </div>   
                                                )
                                              }
                                            </div>
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                          {
                                            user.connectionStatus === "online" ?
                                            (
                                              <span className='fs-7 mb-1 text-success'>Online</span>
                                            )
                                            :
                                            (
                                              <span className='text-muted fs-7 mb-1'>Offline</span>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='separator separator-dashed d-none'></div>
                                  </>
                                )
                              })
                            }
                            </>
                          )
                        }
                        </>
                      )
                    }
                    </>
                  )
                }

              {/*
                chatList.map((user: any) => {
                  const lastMessage: any = getSnapshot(mstGeneral).chat_details.find((x: any) => x.channelId === `private.${user.chat_uuid}`)?.messages[getSnapshot(mstGeneral).chat_details.find((x: any) => x.channelId === `private.${user.chat_uuid}`)?.messages.length - 1]?.message?.message

                  return (
                    <>
                      <a onClick={() => { onClickUser(user) }} className="cursor-pointer">
                        <div className='d-flex flex-stack py-4'>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px symbol-circle'>
                              <img src={`https://ui-avatars.com/api/?name=${user.name}&background=random`} />
                            </div>

                            <div className='ms-5'>
                              <div className='d-flex align-items-center'>
                                <div className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-1'>
                                  {user.name}
                                </div>
                              </div>
                              <div className='text-gray-400'>{lastMessage}</div>
                            </div>
                          </div>

                          <div className='d-flex flex-column align-items-end ms-2'>
                            {
                              user.connectionStatus === "online" ?
                              (
                                <div className="d-flex align-items-center">
                                  <span className='badge badge-success badge-circle w-10px h-10px me-2'></span>
                                  <span className='fs-7 fw-bold text-gray-400'>Online</span>
                                </div>
                              )
                              :
                              (
                                <div className="d-flex align-items-center">
                                  <span className='badge badge-secondary badge-circle w-10px h-10px me-2'></span>
                                  <span className='fs-7 fw-bold text-gray-400'>Offline</span>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </a>
                      <div className='separator separator-dashed d-none'></div>
                    </>
                  )
                })
              */}
              {/* <ul>
                {
                  data.map((user: any) => {
                    return (
                      <li className="cursor-point" onClick={() => { onClickUser(user) }}>{user.name}</li>
                    )
                  })
                }
              </ul> */}
              {/* <div>
                <div>You are now chatting <b>{selectedUser.name}</b></div>
                <div>History:</div>
                {
                  messageHistory.map((msg: any) => {
                    return (
                      <div>
                        {msg.message}
                      </div>
                    )
                  })
                }
                <div></div>
                <input value={message} onChange={(event) => { setMessage(event.target.value) }} />
                <button onClick={() => { onSendChat() }} >Send</button>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        {
          selectedUser.chat_uuid !== undefined &&
          (
            <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
              <div className='card p-0' id='kt_chat_messenger'>
                <div className='card-header px-5' id='kt_chat_messenger_header'>
                  <div className='card-title'>
                    <div className='symbol-group symbol-hover'>
                      <div className='d-flex justify-content-center flex-column me-3'>
                        <a
                          href='#'
                          className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                        >
                          {selectedUser?.name}
                        </a>
                        <div className='mb-0 lh-1'>
                          {
                            selectedUser.connectionStatus === 'online' ?
                            (
                              <>
                                <span className='badge badge-success badge-circle w-10px h-10px me-2'></span>
                                <span className='fs-7 fw-bold text-gray-400'>Online</span>
                              </>
                            )
                            :
                            (
                              <>
                                <span className='badge badge-secondary badge-circle w-10px h-10px me-2'></span>
                                <span className='fs-7 fw-bold text-gray-400'>Offline</span>
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-toolbar'>
                    
                  </div>
                </div>
                <div
                  className='card-body pb-0 p-0'
                  id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
                >
                  <div
                    className='h-min-500px h-500px overflow-scroll p-0 p-5'
                    // className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
                    data-kt-element='messages'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies={
                      isDrawer
                        ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
                        : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                    }
                    data-kt-scroll-wrappers={
                      isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
                    }
                    data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
                  >
                    <>
                      {
                        messageHistory.map((message: any, index: Number) => {
                          // console.log("checkk messages ===> ", messages)
                          // const userInfo = userInfos[newMessage.user]
                          const state = message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'gray-900' : 'primary'
                          const templateAttr = {}
                          if (true) {
                            Object.defineProperty(templateAttr, 'data-kt-element', {
                              value: `template-${message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'in' : 'out'}`,
                            })
                          }
                          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                            message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'start' : 'end'
                          } mb-2`
                          return (
                            <div
                              key={`message${index}`}
                              className={clsx('d-flex', contentClass, 'mb-2', {'d-none': ""})}
                              {...templateAttr}
                            >
                              <div
                                className={clsx(
                                  'd-flex flex-column align-items',
                                  `align-items-${message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'start' : 'end'}`
                                )}
                              >
                                {/* <div className='d-flex align-items-center mb-2'>
                                  {message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? (
                                    <>
                                      <div className='symbol  symbol-35px symbol-circle '>
                                        <img alt='Pic' src={toAbsoluteUrl(`https://ui-avatars.com/api/?name=${message.sender.name}`)} />
                                      </div>
                                      <div className='ms-3'>
                                        <a
                                          href='#'
                                          className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                        >
                                          {message.sender.name}
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className='me-3'>
                                        <a
                                          href='#'
                                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                        >
                                          You
                                        </a>
                                      </div>
                                      <div className='symbol symbol-35px symbol-circle '>
                                      <img alt='Pic' src={toAbsoluteUrl(`https://ui-avatars.com/api/?name=${message.sender.name}`)} />
                                      </div>
                                    </>
                                  )}
                                </div> */}

                                {/* <div
                                  className={clsx(
                                    'p-5 rounded',
                                    `bg-light-${state}`,
                                    'text-dark fw-bold mw-lg-400px',
                                    `text-${message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'start' : 'end'}`
                                  )}
                                  data-kt-element='message-text'
                                  dangerouslySetInnerHTML={{__html: message.message}}
                                ></div> */}
                                <div
                                  style={{
                                    borderRadius: '15px',
                                    borderTopLeftRadius: message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? '0' : '15px',
                                    borderTopRightRadius: message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? '15px' : '0'
                                  }}
                                  className={clsx(
                                    'px-4 py-3 shadow fs-6',
                                    `bg-${state}`,
                                    'text-white fw-bold mw-lg-400px',
                                    `text-${message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'start' : 'end'}`
                                  )}
                                >
                                  <div className="mb-2" dangerouslySetInnerHTML={{__html: message.message}}></div>
                                  <span className='text-gray-400 fs-8'>{moment(message.timestamp).format("hh:mm A")}</span>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </>
                    <div className='mt-0' ref={bottomRef}></div>
                  </div>
                  <div
                    className='card-footer p-0'
                    id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
                  > 
                  {
                    false ?
                    (
                        <>
                          {/* <button onClick={() => { deleteMessages() }} className='btn btn-primary me-2'>Delete Selected Messages</button> */}
                          {/* <button onClick={() => { onUpdateSelectedMessages ? onUpdateSelectedMessages([]) : null }} className='btn btn-primary'>Unselect All</button> */}
                          {/* <button onClick={() => { deleteAllMessages() }} className='btn btn-primary'>Delete All</button> */}
                        </>
                    )
                    :
                    (
                      <div className="d-flex p-5">
                        <textarea
                          autoFocus
                          className='form-control form-control-flush mb-0 me-4 bg-gray-200 rounded'
                          rows={1}
                          data-kt-element='input'
                          placeholder='Type a Message'
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          // onKeyDown={onEnterPress}
                        ></textarea>

                        <div className='d-flex flex-stack justify-content-end'>
                          {/* <div className='d-flex align-items-center me-2'>
                            <button
                              className='btn btn-sm btn-icon btn-active-light-primary me-1'
                              type='button'
                              data-bs-toggle='tooltip'
                              title='Coming soon'
                            >
                              <i className='bi bi-paperclip fs-3'></i>
                            </button>
                            <button
                              className='btn btn-sm btn-icon btn-active-light-primary me-1'
                              type='button'
                              data-bs-toggle='tooltip'
                              title='Coming soon'
                            >
                              <i className='bi bi-upload fs-3'></i>
                            </button>
                          </div> */}
                          <a
                            className={`${message === '' ? 'disabled' : ''} btn btn-primary`}
                            // disabled={newMessage === ''}
                            type='button'
                            // data-kt-element='send'
                            onClick={() => { onSendChat()} }
                          >
                            Send
                          </a>
                        </div>
                      </div>
                    )
                  }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </>
    </div>
  )
})

export default TestChatPage;