import React, {Suspense, lazy, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import { PubNubProvider, usePubNub } from 'pubnub-react'
import { getSnapshot } from 'mobx-state-tree'
import { mstAuth, mstGeneral } from '../mobx'
import Pubnub from 'pubnub'
import PermissionComponent from '../pages/customComponents/PermissionComponent'
import TestChatPage from '../pages/admin/test/chat'
import { PageTitle } from '../../_metronic/layout/core'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const AdminDashboard = lazy(() => import('../pages/admin/dashboard'))
  const AdminTournaments = lazy(() => import('../pages/admin/tournament'))
  const AdminUsers = lazy(() => import('../pages/admin/user'))
  const AdminTeams = lazy(() => import('../pages/admin/team'))
  const AdminGames = lazy(() => import('../pages/admin/game'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const AdminSettings = lazy(() => import('../pages/admin/setting'))

  return (
    // <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/admin/dashboard' component={AdminDashboard} />
        <Route path='/admin/settings' component={AdminSettings} />
        <Route path='/admin/tournaments' component={AdminTournaments} />
        <Route path='/admin/users' component={AdminUsers} />
        <Route path='/admin/teams' component={AdminTeams} />
        <Route path='/admin/games' component={AdminGames} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        {/* <Route path='/admin/messages' component={ChatPage} /> */}
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/admin/messages'>
          <PageTitle breadcrumbs={[]}>Chat Support</PageTitle>
          <TestChatPage />
        </Route>
        <Redirect from='/auth' to='/admin/dashboard' />
        <Redirect exact from='/' to='/admin/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    
    // </Suspense>
  )
}
