/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column flex-root" /* style={{ backgroundImage: 'url("/todak-arena-bg.png")', backgroundSize: 'cover' }} */
      >
        <div className="d-flex flex-column flex-column-fluid flex-lg-row">
          <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
            <div className="d-flex flex-center flex-lg-start flex-column">
              <a href="#" className="mb-2">
                <img
                  alt="Logo"
                  src={"/todak-arena-logo.png"}
                  className="h-250px"
                />
              </a>
              <div className="text-white h2 fw-normal mt-8">
                E-Sports Tournament Management
              </div>
              <div className="d-flex align-items-end mt-1">
                <div className="text-white h5 fw-normal">Powered by</div>
                <img
                  alt="Logo"
                  src={"/todak-logo-white.png"}
                  className="h-30px"
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-center w-lg-50 p-10">
            <div
              className="card rounded-3 w-md-550px shadow"
              style={{ backgroundColor: "#ffffff2e" }}
            >
              <div className="card-body p-10 p-lg-20">
                <Switch>
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/registration" component={Registration} />
                  <Route
                    path="/auth/forgot-password"
                    component={ForgotPassword}
                  />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
