/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login } from "../redux/AuthCRUD";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { mstAuth } from "../../../mobx";
import TimerCountdown from "../../../pages/customComponents/TimerCountdown";
import { getSnapshot } from "mobx-state-tree";

const emailSchema = Yup.object().shape({
  // needOtp: Yup.boolean(),
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email or Phone is required"),
  // password: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Password is required'),
  // otp: Yup.string()
  //   .min(6, 'Minimum 6 digits')
  //   .max(6, 'Maximum 6 digits')
  //   .when('needOtp', {
  //     is: true,
  //     then: Yup.string().required('OTP is required')
  //   })
});
const otpSchema = Yup.object().shape({
  // needOtp: Yup.boolean(),
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email or Phone is required"),
  // password: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Password is required'),
  otp: Yup.string()
    .min(6, "Minimum 6 digits")
    .max(6, "Maximum 6 digits")
    .required("OTP is required"),
});

const initialValues = {
  email: "",
  // password: 'demo',
  otp: "",
  // needOtp: ''
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loginParams, setLoginParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [canResend, setCanResend] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: !otpSent ? emailSchema : otpSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoginParams(values);
      // console.log("Login", JSON.stringify(values, null, 2));
      if (!otpSent) {
        setLoading(true);
        mstAuth
          .adminLoginSendOtp({ data: values.email })
          .then((response: any) => {
            setLoading(false);
            setOtpSent(true);
            setCanResend(false);
            console.log("check response ===> ", response);
            console.log("check formik values ===> ", values);
          })
          .catch((error: any) => {
            setLoading(false);
            setStatus("The login detail is incorrect");
            console.log("check error ===> ", error);
          });
      } else {
        setLoading(true);
        console.log("Login", JSON.stringify(values, null, 2));

        mstAuth
          .adminLoginOtpVerify({ data: values.email, otp: values.otp })
          .then((loginResponse) => {
            console.log("check loginresponse ===> ", loginResponse);
            mstAuth
              .fetchSingleUser(loginResponse.user.user_uuid)
              .then((singleUserResponse) => {
                console.log(
                  "check singleUserResponse ===> ",
                  singleUserResponse
                );
                mstAuth.updateCurrentUserDetail(singleUserResponse);
                mstAuth
                  .fetchSingleRole(singleUserResponse.role[0])
                  .then((fetchSingleRoleResponse) => {
                    mstAuth
                      .updateCurrentUserPermissions(
                        fetchSingleRoleResponse.map((i: any) => i.name)
                      )
                      .then((updateCurrentUserPermissionsResponse) => {
                        window.location.reload();
                      });
                  });
              });
            setLoading(false);
            console.log("check loginResponse ===> ", loginResponse);
          })
          .catch((error: any) => {
            setLoading(false);
            setStatus("The login detail is incorrect");
            console.log("check error ===> ", error);
          });
      }
      // setLoading(true)
      // setTimeout(() => {
      //   login(values.email, values.password)
      //     .then(({data: {api_token}}) => {
      //       setLoading(false)
      //       dispatch(auth.actions.login(api_token))
      //     })
      //     .catch(() => {
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  });

  const onResendOtp = (params: any) => {
    console.log("check loginParams, ", params);
    // setLoginParams(params)
    mstAuth
      .adminLoginSendOtp({ data: params.email })
      .then((response) => {
        setOtpSent(true);
        setCanResend(false);
        // navigate('/admin/register/otp_verification')
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log("check mobx store ===> ", getSnapshot(mstAuth));
  }, []);

  return (
    <form
      className="form w-100 text-light"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        {/* <a href='#'>
          <img alt='Logo' src={toAbsoluteUrl('https://todak.com/store/wp-content/uploads/2018/12/todak_logo_black.png')} className='h-80px mb-10' />
        </a> */}
        <div className="text-center mb-11">
          <h1 className="text-light mb-3">Sign In Todak Arena</h1>
          {/* <div className="text-gray-500 fw-semibold fs-6">E-Sports Tournament Management</div> */}
        </div>
        <div className="separator separator-content my-14">
          <span className="w-400px text-light fw-semibold fs-7">
            Login with Email or Phone
          </span>
        </div>
        {/* <h1 className='text-dark mb-3'>Sign In to Todak Arena</h1> */}
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {
        formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
      }

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        {/* <label className='form-label fs-6 fw-bolder text-dark'>Email or Phone</label> */}
        <input
          disabled={otpSent}
          placeholder="Email or Phone"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="on"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container m-2">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* <input 
        type="hidden" 
        {...formik.getFieldProps('needOtp')}
        name='needOtp' 
        value={`${otpSent}`}
      /> */}
      {/* end::Form group */}

      {/* begin::Form group */}
      {otpSent ? (
        <div className="fv-row mb-10">
          <div className="d-flex justify-content-between mt-0">
            <div className="d-flex flex-stack mb-0">
              {/* begin::Label */}
              {/* <label className='form-label fw-bolder text-dark fs-6 mb-0'>OTP</label> */}
              {/* end::Label */}
              {/* begin::Link */}
              {/* <Link
                  to='/auth/forgot-password'
                  className='link-primary fs-6 fw-bolder'
                  style={{marginLeft: '5px'}}
                >
                  Forgot Password ?
                </Link> */}
              {/* end::Link */}
            </div>
          </div>
          <div className="d-flex">
            <div className="w-100">
              <input
                placeholder="6 Digits OTP"
                type="text"
                autoComplete="off"
                {...formik.getFieldProps("otp")}
                className={clsx(
                  "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid": formik.touched.otp && formik.errors.otp,
                  },
                  {
                    "is-valid": formik.touched.otp && !formik.errors.otp,
                  }
                )}
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <button
                type="button"
                disabled={!canResend}
                onClick={() => {
                  onResendOtp(loginParams);
                }}
                className="text-light btn btn-lg"
                style={{ backgroundColor: "#06155d" }}
              >
                {canResend ? (
                  "Resend"
                ) : (
                  <TimerCountdown
                    initialMinute={1}
                    initialSeconds={59}
                    isTimerEnd={setCanResend}
                    isRestartTimer={!canResend}
                  />
                )}
              </button>
            </div>
          </div>
          {formik.touched.otp && formik.errors.otp && (
            <div className="fv-plugins-message-container m-2">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.otp}</span>
              </div>
            </div>
          )}
        </div>
      ) : null}
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg w-100 mb-0 text-light"
          style={{ backgroundColor: "#06155d" }}
          disabled={loading}
        >
          {!loading && (
            <span className="indicator-label">
              {otpSent ? "Login" : "Continue"}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        {/* begin::Separator
        <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>
        {/* end::Separator */}

        {/* begin::Google link*/}
        {/* <a
          href="#"
          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
            className="h-20px me-3"
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link 
        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a>
        {/* end::Google link */}

        {/* begin::Google link
        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a>
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  );
}
