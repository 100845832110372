import clsx from "clsx";
import { getSnapshot } from "mobx-state-tree";
import React, { FC } from "react";
import { mstAuth } from "../../../../app/mobx";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
} from "../../../partials";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "svg-icon-1";
const Topbar: FC = () => {
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className="topbar d-flex align-items-stretch flex-shrink-0">
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div> */}
        {/* Activities */}
        {/* <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}> */}
        {/* begin::Drawer toggle */}
        {/* <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div> */}
        {/* end::Drawer toggle */}
        {/* </div> */}

        {/* NOTIFICATIONS */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
        {/* begin::Menu- wrapper */}
        {/* <div
              className={clsx(
                  'btn btn-icon btn-active-light-primary btn-custom',
                  toolbarButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
          >
            <KTSVG
                path='/media/icons/duotune/general/gen022.svg'
                className={toolbarButtonIconSizeClass}
            />
          </div>
          <HeaderNotificationsMenu /> */}
        {/* end::Menu wrapper */}
        {/* </div> */}

        {/* CHAT */}
        <a href="/admin/messages">
          <div
            className={clsx(
              "d-flex align-items-center me-3",
              toolbarButtonMarginClass
            )}
          >
            {/* begin::Menu wrapper */}
            <div
              className={clsx(
                "btn btn-icon btn-active-light-primary btn-custom position-relative",
                toolbarButtonHeightClass
              )}
              // id='kt_drawer_chat_toggle'
            >
              <KTSVG
                path="/media/icons/duotune/communication/com012.svg"
                className={toolbarButtonIconSizeClass}
              />
              <span className="bullet bullet-dot bg-success h-8px w-8px position-absolute translate-middle top-0 start-50 animation-blink fs-8 text-white"></span>
            </div>
            {/* <span className='p-1 fs-8 bg-warning rounded left-20 text-white position-absolute'>50</span> */}
            {/* end::Menu wrapper */}
          </div>
        </a>

        {/* Quick links */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}> */}
        {/* begin::Menu wrapper */}
        {/* <div
              className={clsx(
                  'btn btn-icon btn-active-light-primary btn-custom',
                  toolbarButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
          >
            <KTSVG
                path='/media/icons/duotune/general/gen025.svg'
                className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks /> */}
        {/* end::Menu wrapper */}
        {/* </div> */}

        {/* begin::User */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
          id="kt_header_user_menu_toggle"
        >
          {/* begin::Toggle */}
          <div
            className={clsx(
              "cursor-pointer symbol",
              toolbarUserAvatarHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <img
              className="h-40px w-40px rounded"
              src={toAbsoluteUrl(
                `${
                  getSnapshot(mstAuth).userDetail?.detail
                    ?.profile_image_path !== ""
                    ? getSnapshot(mstAuth).userDetail?.detail
                        ?.profile_image_path
                    : `https://ui-avatars.com/api/?name=${
                        getSnapshot(mstAuth).userDetail?.username
                      }`
                }`
              )}
              // src={toAbsoluteUrl(getSnapshot(mstAuth).userDetail?.detail?.profile_image_path)}
              // alt='metronic'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  );
};

export { Topbar };
