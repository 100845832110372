import React from "react";
import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MegaMenu } from "./MegaMenu";
import { useIntl } from "react-intl";
import PermissionComponent from "../../../../app/pages/customComponents/PermissionComponent";

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      <MenuItem
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        to="/admin/dashboard"
      />
      <MenuItem title="Teams" to="/admin/teams" />
      <MenuItem title="Tournaments" to="/admin/tournaments" />
      <MenuItem title="Games" to="/admin/games" />
      {/* <MenuItem title='Users' to='/admin/users' /> */}
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      <MenuInnerWithSub
        title="Users"
        to="/admin/users"
        menuPlacement="bottom-start"
        menuTrigger="click"
      >
        <MenuItem to="/admin/users/list" title="All Users" hasBullet={false} />
        <PermissionComponent can="role-index">
          <MenuItem
            to="/admin/users/roles-and-privileges"
            title="Roles & Privileges"
            hasBullet={false}
          />
        </PermissionComponent>
        <MenuItem
          to="/admin/users/deletion-request"
          title="Deletion Request"
          hasBullet={false}
        />
        {/* <MenuItem to='/admin/users/log' title='User Log' hasBullet={false} /> */}
        {/* <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          icon='/media/icons/duotune/general/gen022.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub> */}
      </MenuInnerWithSub>

      <PermissionComponent can="configuration-show">
        <MenuInnerWithSub
          title="Settings"
          to="/admin/settings"
          menuPlacement="bottom-start"
          menuTrigger="click"
        >
          <MenuItem
            to="/admin/settings/about"
            title="About"
            hasBullet={false}
          />
          <MenuItem
            to="/admin/settings/terms-and-conditions"
            title="Terms & Conditions"
            hasBullet={false}
          />
          <MenuItem
            to="/admin/settings/privacy-policy"
            title="Privacy Policy"
            hasBullet={false}
          />
        </MenuInnerWithSub>
      </PermissionComponent>

      {/* ACCOUNT */}
      {/* <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub> */}

      {/* ERRORS */}
      {/* <MenuInnerWithSub
          title='Errors'
          to='/error'
          icon='/media/icons/duotune/technology/teh004.svg'
          hasArrow={true}
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub> */}

      {/* Widgets */}
      {/* <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          icon='/media/icons/duotune/general/gen025.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}

      {/* <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'> */}
      {/* PAGES */}
      {/* <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>
      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  );
}
