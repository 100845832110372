import React, { useEffect, useRef } from "react";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/toolbar/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider, PageExtraHeader, useLayout } from "./core";
import { useHistory, useLocation } from "react-router-dom";
import {
  DrawerMessenger,
  RightToolbar,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from "../partials";
import { MenuComponent } from "../../_metronic/assets/ts/components";
import clsx from "clsx";
import Pubnub from "pubnub";
import { getSnapshot } from "mobx-state-tree";
import { mstAuth, mstGeneral, mstNotification } from "../../app/mobx";
import { PubNubProvider } from "pubnub-react";
import { observer } from "mobx-react";
import { notification } from "antd";
import { toast } from "react-toastify";

const MasterLayout: React.FC = observer(({ children }) => {
  const { classes } = useLayout();
  const history = useHistory();

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  const stagingPubnubPubKey = "pub-c-70a06110-b28e-4aa6-bb1b-9ac2b15349ba";
  const stagingPubnubSubKey = "sub-c-c9237c5e-bb71-4421-926f-0873b7198b7a";
  const prodPubnubPubKey = "pub-c-2a780bd7-99b0-4270-a9be-a270ae837dd3";
  const prodPubnubSubKey = "sub-c-4c79684c-6587-4dc1-882d-2672110a1310";

  const pubnub = new Pubnub({
    publishKey: stagingPubnubPubKey,
    subscribeKey: stagingPubnubSubKey,
    uuid: `${getSnapshot(mstAuth).userDetail.user_uuid}`,
  });

  useEffect(() => {
    // pubnub.unsubscribeAll()
    // const channels = [getSnapshot(mstGeneral).GLOBAL_CHANNEL_CHAT[0], getSnapshot(mstAuth).userDetail.user_uuid]
    const channels = [
      getSnapshot(mstGeneral).GLOBAL_CHANNEL_CHAT[0],
      getSnapshot(mstAuth).userDetail.user_uuid,
    ];
    pubnub.subscribe({ channels: channels, withPresence: true });
    console.log("Check pubnub channels ===> ", channels);
  }, []);

  const checkPresence = () => {
    pubnub.hereNow(
      {
        channels: [getSnapshot(mstGeneral).GLOBAL_CHANNEL_CHAT[0]],
        includeState: true,
      },
      function (status, response) {
        const arrayUuids: any = [];
        if (
          response.channels[getSnapshot(mstGeneral).GLOBAL_CHANNEL_CHAT[0]]
            .occupants.length > 0
        ) {
          response.channels[
            getSnapshot(mstGeneral).GLOBAL_CHANNEL_CHAT[0]
          ].occupants
            .filter((x) => x.uuid !== getSnapshot(mstAuth).userDetail.user_uuid)
            .map((item) => {
              arrayUuids.push(item.uuid);
            });
        }
        // setActiveUuids(arrayUuids)
        console.log("ArrayUuids", arrayUuids);
        mstGeneral.updateActiveUsers(arrayUuids);
      }
    );
  };

  const getPubnubUnreadCount = (channels: any) => {
    console.log(
      "check getPubnubUnreadCount ===> ",
      channels,
      channels.map((x: any) => x.messages[x.messages.length - 1]?.timetoken)
    );

    const filteredChannels: any = channels.filter(
      (x: any) => x.messages.length > 0
    );

    pubnub
      .messageCounts({
        channels: filteredChannels.map((x: any) => x.channelId),
        channelTimetokens: filteredChannels.map(
          (x: any) => x.messages[x.messages.length - 1]?.timetoken
        ),
      })
      .then((response) => {
        // console.log("response getMessageCounts ===> ", response);
        const messagesArray: any = [];
        channels.map((_channel: any) => {
          messagesArray.push({
            unreadCount:
              response?.channels[_channel.channelId] !== undefined
                ? response?.channels[_channel.channelId]
                : 0,
            channelId: _channel.channelId,
          });
        });
        // console.log("check getMessageCounts ===> ", messagesArray)
        mstGeneral.updateChatUnreadCounts(messagesArray);
      })
      .catch((error) => {
        // console.log("error getMessageCounts ===> ", error);
      });
  };

  const getPubnubMessages = (channels: any) => {
    // console.log("check getPubnubMessages ===> ", channels)
    pubnub.fetchMessages(
      {
        channels: channels,
        end: "",
        count: 500, // default/max is 25 messages for multiple channels (up to 500)
      },
      function (status, response) {
        // console.log("status fetchMessages ===> ", status);
        // console.log("results fetchMessages ===> ", response);
        const messagesArray: any = [];
        channels.map((_channel: any) => {
          // if (response?.channels[_channel] !== undefined) {
          const messagesContent: any = [];
          if (response?.channels[_channel] !== undefined) {
            response?.channels[_channel].map((msg: any, msgIndex: any) => {
              messagesContent.push({
                ...msg,
                isRead: true,
              });
            });
          }
          messagesArray.push({
            messages: messagesContent,
            channelId: _channel,
          });
          // messagesArray.push({ lastTimetoken: response?.channels[_channel][response?.channels[_channel].length - 1].timetoken, channelId: _channel })
          // } else {
          //   messagesArray.push({ messages: [], channelId: _channel })
          // }
        });
        console.log("check getPubnubMessages ===> ", messagesArray);
        // getPubnubUnreadCount(messagesArray)
        mstGeneral.updateChatDetails(messagesArray);
        // console.log("check getLastMessageTimetokenArray ===> ", getLastMessageTimetokenArray)
      }
    );
  };

  useEffect(() => {
    mstAuth
      .fetchChatList()
      // mstAuth.fetchUsers()
      .then((response) => {
        // console.log("check response =======================> ", response)
        const dataArray: any = [];
        const filteredResponse: any = response
          ?.filter((x: any) => x !== null)
          .filter(
            (x: any) =>
              x?.user_uuid !== getSnapshot(mstAuth).userDetail.user_uuid
          );
        filteredResponse.map((res: any, resIndex: number) => {
          dataArray.push({
            key: resIndex + 1,
            name: res?.name,
            chat_uuid: res?.chat_uuid,
            user_uuid: res?.user_uuid,
          });
        });
        getPubnubMessages(
          filteredResponse.map(
            (res: any, resIndex: number) => `private.${res?.chat_uuid}`
          )
        );
        // setIsLoadingChatList(false)
        // setChatList(dataArray)
      })
      .catch((error) => {
        // setIsLoadingChatList(false)
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   if (getSnapshot(mstNotification).isTriggered) {
  //     console.log("trigger notification")
  //     if (getSnapshot(mstNotification).messageNotification.recipient.uuid === getSnapshot(mstAuth).userDetail.user_uuid) {
  //       pubnub
  //       .publish({ channel: `${getSnapshot(mstNotification).messageNotification.recipient.uuid}`, message: { to: `${getSnapshot(mstNotification).messageNotification.recipient.uuid}`, content: `new message from ${getSnapshot(mstNotification).messageNotification.sender.name}` } })
  //     //   var message = `new message from ${getSnapshot(mstNotification).messageNotification.sender.name}`
  //     //   if (!("Notification" in window)) {
  //     //     alert("This browser does not support system notifications");
  //     //   }
  //     //   else if (Notification.permission === "granted") {
  //     //     if(typeof message === 'string'){
  //     //       var notification = new Notification(message);
  //     //     } else{
  //     //       var notification = new Notification("Hello World");
  //     //     }
  //     //   }
  //     //   else if (Notification.permission !== 'denied') {
  //     //     Notification.requestPermission(function (permission) {
  //     //       if (permission === "granted") {
  //     //         var notification = new Notification("Hello World");
  //     //       }
  //     //     });
  //     //   }
  //     }
  //     mstNotification.onTriggerNotification()
  //   }
  // }, [getSnapshot(mstNotification).isTriggered])

  // const handleMessage = (event: any) => {
  //   console.log("RUNNNNNNNNNNN BOY RUNNNNNNNNN")
  //   // const message = event.message;
  //   // if (typeof message === 'object') {
  //     var message = "Test notification"
  //     if (!("Notification" in window)) {
  //       alert("This browser does not support system notifications");
  //     }
  //     else if (Notification.permission === "granted") {
  //       if(typeof message === 'string'){
  //         var notification = new Notification(message);
  //       } else{
  //         var notification = new Notification("Hello World");
  //       }
  //     }
  //     else if (Notification.permission !== 'denied') {
  //       Notification.requestPermission(function (permission) {
  //         if (permission === "granted") {
  //           var notification = new Notification("Hello World");
  //         }
  //       });
  //     }
  //   // }
  // };

  const OLD_openNotificationWithIcon = (event: any) => {
    notification["info"]({
      message: `New Message from ${event.message.sender.name}`,
      description: `${event.message.message}`,
      onClick: () => {
        history.push(
          `/admin/messages/private-chat/${event.message.sender.uuid}`
        );
      },
    });
  };

  const openNotificationWithIcon = (event: any) => {
    toast(event.message.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClick: () => {
        history.push(`/admin/messages?user=${event.message.sender.uuid}`);
      },
    });
  };

  const handleMessage = (event: any) => {
    console.log("handleMessage ===> ", event);
    // const message: any = event.message
    // if (getSnapshot(mstNotification).isTriggered) {
    const newMessageChannel: any = event.channel;
    const currentChannel: any = getSnapshot(mstAuth).userDetail.user_uuid;
    if (newMessageChannel === currentChannel) {
      openNotificationWithIcon(event);
      // var message = event.message.message
      // if (!("Notification" in window)) {
      //   alert("This browser does not support system notifications");
      // }
      // else if (Notification.permission === "granted") {
      //   if(typeof message === 'string'){
      //     var notification = new Notification(message);
      //   } else{
      //     var notification = new Notification("Hello World");
      //   }
      // }
      // else if (Notification.permission !== 'denied') {
      //   Notification.requestPermission(function (permission) {
      //     if (permission === "granted") {
      //       var notification = new Notification("Hello World");
      //     }
      //   });
      // }
      // }
      // mstNotification.onTriggerNotification()
    }
    mstAuth
      .fetchChatList()
      // mstAuth.fetchUsers()
      .then((response) => {
        // console.log("check response =======================> ", response)
        const dataArray: any = [];
        const filteredResponse: any = response
          ?.filter((x: any) => x !== null)
          .filter(
            (x: any) =>
              x?.user_uuid !== getSnapshot(mstAuth).userDetail.user_uuid
          );
        filteredResponse.map((res: any, resIndex: number) => {
          dataArray.push({
            key: resIndex + 1,
            name: res?.name,
            chat_uuid: res?.chat_uuid,
            user_uuid: res?.user_uuid,
          });
        });
        getPubnubMessages(
          filteredResponse.map(
            (res: any, resIndex: number) => `private.${res?.chat_uuid}`
          )
        );
        getPubnubUnreadCount(getSnapshot(mstGeneral).chat_details);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    checkPresence();
  }, [pubnub]);

  const handlePresence = () => {
    checkPresence();
  };

  useEffect(() => {
    pubnub.addListener({
      message: handleMessage,
      presence: handlePresence,
    });

    return () => {
      pubnub.removeListener({
        message: handleMessage,
        presence: handlePresence,
      });
    };
  }, [pubnub]);

  useEffect(() => {
    if (getSnapshot(mstAuth).userDetail.user_uuid !== undefined) {
      // console.log("check updateResponse ===> ", getSnapshot(mstAuth).userDetail.user_uuid)
      mstAuth
        .fetchSingleUser(getSnapshot(mstAuth).userDetail.user_uuid)
        .then((singleUserResponse) => {
          // console.log("check singleUserResponse ===> ", singleUserResponse)
          mstAuth.updateCurrentUserDetail(singleUserResponse);
          mstAuth
            .fetchSingleRole(singleUserResponse.role[0])
            .then((fetchSingleRoleResponse) => {
              mstAuth.updateCurrentUserPermissions(
                fetchSingleRoleResponse.map((i: any) => i.name)
              );
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [getSnapshot(mstAuth).userDetail.user_uuid]);

  // useEffect(() => {
  //   if (getSnapshot(mstNotification).isTriggered) {
  //     pubnub
  //     .publish({ channel: `${getSnapshot(mstNotification).messageNotification.recipient.uuid}`, message: getSnapshot(mstNotification).messageNotification.message })
  //   }
  // }, [getSnapshot(mstNotification).isTriggered])

  return (
    <PubNubProvider client={pubnub}>
      <PageDataProvider>
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <HeaderWrapper />

            <div
              id="kt_content"
              className="content d-flex flex-column flex-column-fluid mb-2"
            >
              <Toolbar />
              {/* <PageExtraHeader /> */}
              <div
                className={clsx(
                  "d-flex flex-column-fluid align-items-start",
                  classes.contentContainer.join(" ")
                )}
                id="kt_post"
              >
                {/* <AsideDefault /> */}
                <Content>{children}</Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <Main />
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </PageDataProvider>
    </PubNubProvider>
  );
});

export { MasterLayout };
