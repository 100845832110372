import { observer } from "mobx-react"
import { getSnapshot } from "mobx-state-tree"
import { mstAuth } from "../../mobx"

const PermissionComponent = observer((props: any) => {
  const permissions: any = getSnapshot(mstAuth).userPermissions
  const canAccess: any = permissions.includes(props.can)

  if (canAccess) {
    return (
      <>{props.children}</>
    )
  } else {
    return (
      null
    )
  }
})

export default PermissionComponent;