import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { mstAuth } from "../../mobx";
import {
  createTheme,
  Match,
  SingleEliminationBracket,
  SVGViewer,
} from "@g-loot/react-tournament-brackets";
import { FaChartLine, FaGamepad } from "react-icons/fa";
import moment from "moment";
import RoundRobinBracket from "./RoundRobinBracket";
// single elimination - [4, 8, 16, 32, 64, 128, 256, 512]
// double elimination - [8, 16, 32, 64, 128, 256, 512]

interface Size {
  width: number | undefined;
  height: number | undefined;
}

function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const BracketComponent = (props: any) => {
  const { id }: any = useParams();
  const [data, setData] = useState({
    data: [],
    format: "",
    customMatches: [],
    grouping: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const size: Size = useWindowSize();
  const finalWidth = Math.max(size.width || 0 - 50, 500);
  const finalHeight = Math.max(size.height || 0 - 100, 500);
  const [currentTab, setCurrentTab] = useState(1);
  const [bracketView, setBracketView] = useState("singleElimination");

  // const location = useLocation()
  // const urlQuery = new URLSearchParams(location.search)
  // const searchKeyword = urlQuery.get('search')

  // useEffect(() => {
  //   console.log("check search keyword ===> ", searchKeyword)
  // }, [searchKeyword])

  useEffect(() => {
    if (id !== undefined) {
      mstAuth
        .fetchSingleBracket({ id: id })
        .then((response) => {
          console.log("chec:===> ", response);
          // const apiRounds: any = response
          // if (apiRounds) {
          //   setData(apiRounds)
          setData({
            data: response.data,
            format: response.format,
            customMatches: response.customMatches,
            grouping: response.grouping || [],
          });
          setIsLoading(false);
          // } else {
          //   setIsLoading(false)
          // }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [id]);

  const onChangeTab = (tabIndex: any) => {
    setCurrentTab(tabIndex);
  };

  console.log("check data bracket ===> ", data);

  return (
    <>
      {isLoading ? (
        // CONDITION 1: Is the data loading?
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "#080C30",
            minHeight: "100vh",
            height: "100%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <div className="text-center">
            <div>
              <img src="/todak-arena-logo.png" className="w-100px" />
            </div>
            <div className="mt-5 spinner-border text-white" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        // If not loading, then check:
        <>
          {data.data.length === 0 ? (
            // CONDITION 2: Is there no tournament data, then show this (Tournament not started yet):
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#080C30",
                minHeight: "100vh",
                height: "100%",
                width: "100%",
                overflowY: "auto",
              }}
            >
              <div className="text-center">
                <div>
                  <img src="/todak-arena-logo.png" className="w-100px" />
                </div>
                <div className="text-white mt-5">
                  Tournament not started yet. <br />
                  Please come again later
                </div>
              </div>
            </div>
          ) : (
            // If there is tournament data, then check:
            <div
              style={{
                backgroundColor: "#080C30",
                minHeight: "100vh",
                height: "100%",
              }}
            >
              {data.format !== "round-robin" && (
                <div
                  className="d-flex px-8 pt-8"
                  style={{ backgroundColor: "#080C30" }}
                >
                  <div
                    onClick={() => {
                      onChangeTab(1);
                    }}
                    className={`btn btn-sm btn-secondary me-4 overflow-auto`}
                    style={{ opacity: currentTab === 1 ? 1 : 0.7 }}
                  >
                    Bracket
                  </div>
                  {data.customMatches.length > 0 && (
                    <div
                      onClick={() => {
                        onChangeTab(2);
                      }}
                      className={`btn btn-sm btn-secondary`}
                      style={{ opacity: currentTab === 2 ? 1 : 0.7 }}
                    >
                      Custom Matches
                    </div>
                  )}
                </div>
              )}
              <div
                style={{
                  backgroundColor: "#080C30",
                  zIndex: -1,
                  minHeight: "calc(100vh - 200px)",
                }}
              >
                <div className="bg-black overflow-auto position-absolute "></div>
                {data.format === "round-robin" ? (
                  // CONDITION 3: Is it a round-robin format?
                  <RoundRobinBracket
                    grouping={data.grouping}
                    data={data.data}
                  />
                ) : (
                  // If not round-robin, then show tabs and check:
                  <>
                    {currentTab === 1 ? (
                      // CONDITION 4: Is user on bracket tab?
                      <>
                        {/* Show bracket type tabs */}
                        {data.format !== "round-robin" && (
                          <div
                            className="d-flex px-8 pt-4 overflow-auto"
                            style={{ backgroundColor: "#080C30" }}
                          >
                            <div
                              onClick={() => setBracketView("roundRobin")}
                              className={`btn btn-sm ${
                                bracketView === "roundRobin"
                                  ? "btn-primary"
                                  : "btn-outline-primary text-white"
                              } me-4`}
                            >
                              Group Stage
                            </div>
                            <div
                              onClick={() =>
                                setBracketView("singleElimination")
                              }
                              className={`btn btn-sm ${
                                bracketView === "singleElimination"
                                  ? "btn-primary"
                                  : "btn-outline-primary text-white"
                              }`}
                            >
                              Knockout Stage
                            </div>
                          </div>
                        )}

                        {/* Bracket Display */}
                        {bracketView === "roundRobin" ? (
                          // CONDITION 5: Which bracket view is selected?
                          <RoundRobinBracket
                            grouping={data.grouping}
                            data={data.data}
                          />
                        ) : (
                          <div
                            style={{
                              minHeight: "100vh",
                              height: "100%",
                              overflowY: "auto",
                            }}
                          >
                            <SingleEliminationBracket
                              theme={GlootTheme}
                              matches={data.data}
                              matchComponent={Match}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      // If not on bracket tab, show custom matches
                      <div
                        className="p-8 d-flex"
                        style={{ fontFamily: "Helvetica" }}
                      >
                        {data.customMatches.map((item: any, itemIndex: any) => {
                          const teamA: any = item.participants[0];
                          const teamB: any = item.participants[1];

                          const isAWinner =
                            parseInt(teamA.resultText) >
                            parseInt(teamB.resultText);
                          const isBWinner =
                            parseInt(teamB.resultText) >
                            parseInt(teamA.resultText);

                          return (
                            <div
                              className={`w-300px ${
                                itemIndex === 0 ? "ms-0" : "ms-10"
                              }`}
                            >
                              <div
                                style={{
                                  backgroundColor: "#303648",
                                  borderRadius: 2,
                                }}
                                className="py-3 text-center text-white fs-5"
                              >
                                {item.name}
                              </div>
                              <div
                                className="mt-8"
                                style={{ color: "#707582" }}
                              >
                                <div className="mb-1">{item.startTime}</div>
                                <div
                                  className=""
                                  style={{
                                    backgroundColor: "#1c1d2d",
                                    border: "3px solid #2a2b43",
                                    borderRadius: 2,
                                  }}
                                >
                                  <div
                                    className={`d-flex justify-content-between ${
                                      isAWinner ? "bg-success text-white" : ""
                                    }`}
                                    style={{
                                      borderBottom: "3px solid #2a2b43",
                                    }}
                                  >
                                    <div className="px-4 py-1">
                                      {teamA.name}
                                    </div>
                                    <div
                                      className="px-8 py-1"
                                      style={{ backgroundColor: "#0f141c" }}
                                    >
                                      {teamA.resultText}
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex justify-content-between ${
                                      isBWinner ? "bg-success text-white" : ""
                                    }`}
                                  >
                                    <div className="px-4 py-1">
                                      {teamB.name}
                                    </div>
                                    <div
                                      className="px-8 py-1"
                                      style={{ backgroundColor: "#0f141c" }}
                                    >
                                      {teamB.resultText}
                                    </div>
                                  </div>
                                </div>
                                {/* <div className='mt-1 text-center'>{item.name}</div> */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const GlootTheme = createTheme({
  textColor: { main: "#000000", highlighted: "#F4F2FE", dark: "#707582" },
  matchBackground: {
    wonColor: "#ca9703",
    lostColor: "#2e314d",
  },
  score: {
    background: {
      wonColor: `#10131C`,
      lostColor: "#10131C",
    },
    text: { highlightedWonColor: "#7BF59D", highlightedLostColor: "#FB7E94" },
  },
  border: {
    color: "#292B43",
    highlightedColor: "RGBA(152,82,242,0.4)",
  },
  roundHeader: { backgroundColor: "#3B3F73", fontColor: "#F4F2FE" },
  connectorColor: "#3B3F73",
  connectorColorHighlight: "RGBA(152,82,242,0.4)",
  svgBackground: "#0F121C",
});

export const bracketData = [
  {
    id: 19753,
    nextMatchId: null,
    tournamentRoundText: "3",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [],
  },
  {
    id: 19754,
    nextMatchId: 19753,
    tournamentRoundText: "2",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "14754a1a-932c-4992-8dec-f7f94a339960",
        resultText: null,
        isWinner: false,
        status: null,
        name: "CoKe BoYz",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19755,
    nextMatchId: 19754,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCORE_DONE",
    participants: [
      {
        id: "14754a1a-932c-4992-8dec-f7f94a339960",
        resultText: "Won",
        isWinner: true,
        status: "PLAYED",
        name: "CoKe BoYz",
        picture: "teamlogos/client_team_default_logo",
      },
      {
        id: "d16315d4-7f2d-427b-ae75-63a1ae82c0a8",
        resultText: "Lost",
        isWinner: false,
        status: "PLAYED",
        name: "Aids Team",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19756,
    nextMatchId: 19754,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "RUNNING",
    participants: [
      {
        id: "d8b9f00a-0ffa-4527-8316-da701894768e",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Art of kill",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19757,
    nextMatchId: 19753,
    tournamentRoundText: "2",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [],
  },
  {
    id: 19758,
    nextMatchId: 19757,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "9397971f-4b2f-44eb-a094-722eb286c59b",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Crazy Pepes",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19759,
    nextMatchId: 19757,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "42fecd89-dc83-4821-80d3-718acb50a30c",
        resultText: null,
        isWinner: false,
        status: null,
        name: "BLUEJAYS",
        picture: "teamlogos/client_team_default_logo",
      },
      {
        id: "df01fe2c-18db-4190-9f9e-aa63364128fe",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Bosphorus",
        picture: "teamlogos/r7zn4gr8eajivapvjyzd",
      },
    ],
  },
];

export const simpleSmallBracket = [
  {
    id: 19753,
    nextMatchId: null,
    tournamentRoundText: "3",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [],
  },
  {
    id: 19754,
    nextMatchId: 19753,
    tournamentRoundText: "2",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "14754a1a-932c-4992-8dec-f7f94a339960",
        resultText: null,
        isWinner: false,
        status: null,
        name: "CoKe BoYz",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19755,
    nextMatchId: 19754,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCORE_DONE",
    participants: [
      {
        id: "14754a1a-932c-4992-8dec-f7f94a339960",
        resultText: "Won",
        isWinner: true,
        status: "PLAYED",
        name: "CoKe BoYz",
        picture: "teamlogos/client_team_default_logo",
      },
      {
        id: "d16315d4-7f2d-427b-ae75-63a1ae82c0a8",
        resultText: "Lost",
        isWinner: false,
        status: "PLAYED",
        name: "Aids Team",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19756,
    nextMatchId: 19754,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "RUNNING",
    participants: [
      {
        id: "d8b9f00a-0ffa-4527-8316-da701894768e",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Art of kill",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19757,
    nextMatchId: 19753,
    tournamentRoundText: "2",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [],
  },
  {
    id: 19758,
    nextMatchId: 19757,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "9397971f-4b2f-44eb-a094-722eb286c59b",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Crazy Pepes",
        picture: "teamlogos/client_team_default_logo",
      },
    ],
  },
  {
    id: 19759,
    nextMatchId: 19757,
    tournamentRoundText: "1",
    startTime: "2021-05-30",
    state: "SCHEDULED",
    participants: [
      {
        id: "42fecd89-dc83-4821-80d3-718acb50a30c",
        resultText: null,
        isWinner: false,
        status: null,
        name: "BLUEJAYS",
        picture: "teamlogos/client_team_default_logo",
      },
      {
        id: "df01fe2c-18db-4190-9f9e-aa63364128fe",
        resultText: null,
        isWinner: false,
        status: null,
        name: "Bosphorus",
        picture: "teamlogos/r7zn4gr8eajivapvjyzd",
      },
    ],
  },
];

export default BracketComponent;
