import { Tabs } from "antd";
import { FaChartLine, FaGamepad } from "react-icons/fa";
import moment from "moment";

const RoundRobinBracket = ({
  data,
  grouping,
}: {
  data: any;
  grouping: any;
}) => {
  console.log("RoundRobin Component received data:", data);
  console.log("Grouping data:", grouping);

  // Early return if no grouping data
  if (!grouping?.data?.stage) {
    return null;
  }

  const stages = grouping.data.stage;

  return (
    <div
      className="p-8 overflow-auto"
      style={{
        minHeight: "100vh",
        height: "100%",
        backgroundColor: "#080C30",
      }}
    >
      {stages.map((stage: any, stageIndex: number) => (
        <div key={stageIndex}>
          <h2 className="text-white mb-4">{stage.stage_name}</h2>

          {stage.groups.map((group: any, groupIndex: number) => (
            <div key={groupIndex} className="mb-8">
              <div>
                <p className="fs-2 fw-bolder text-white">{group.group_name}</p>

                {/* Tabs */}
                <Tabs defaultActiveKey="standings">
                  {/* Standings */}
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex align-items-center">
                        <FaChartLine className="me-2 text-white" />
                        <div className="text-white">Standings</div>
                      </div>
                    }
                    key="standings"
                  >
                    <div
                      className="mt-2 text-white"
                      style={{
                        backgroundColor: "#080C30",
                        overflowY: "auto",
                        overflowX: "auto",
                      }}
                    >
                      <table
                        className="table text-white mt-2"
                        style={{
                          backgroundColor: "#080C30",
                          overflowY: "auto",
                          overflowX: "auto",
                        }}
                      >
                        <thead className="p-2">
                          <tr className="fw-semibold fs-6 text-white border-bottom border-gray-500">
                            <th scope="col">Rank</th>
                            <th scope="col">Team</th>
                            <th scope="col">Points</th>
                          </tr>
                        </thead>
                        <tbody className="mt-2">
                          {Object.values(group.teams)
                            .sort(
                              (a: any, b: any) => b.team_score - a.team_score
                            )
                            .map((team: any, teamIndex: number) => (
                              <tr
                                key={teamIndex}
                                className="fw-semibold fs-6 text-white border-bottom border-gray-500"
                              >
                                <th scope="row">{teamIndex + 1}</th>
                                <td>{team.team_name}</td>
                                <td className="fs-3 fw-bolder">
                                  {team.team_score}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Tabs.TabPane>

                  {/* Matches */}
                  <Tabs.TabPane
                    tab={
                      <div className="d-flex align-items-center">
                        <FaGamepad className="me-2 text-white" />
                        <div className="text-white">Matches</div>
                      </div>
                    }
                    key="matches"
                  >
                    <div className="mt-2 text-white d-flex flex-column gap-3">
                      <div className="mt-2 row g-4">
                        {group.matches[0]
                          .filter((x: any) => x.match_title === null)
                          .map((match: any, matchIndex: number) => {
                            const isAWinner =
                              match.team_a.team_score > match.team_b.team_score;
                            const isBWinner =
                              match.team_b.team_score > match.team_a.team_score;
                            const isDraw =
                              match.match_status === "ended" &&
                              match.team_a.team_score ===
                                match.team_b.team_score;

                            return (
                              <div
                                key={matchIndex}
                                className="col-12 col-sm-6 col-md-4 col-lg-2"
                              >
                                <div
                                  className="rounded border border-gray-500 h-100 overflow-hidden"
                                  style={{ backgroundColor: "#080C30" }}
                                >
                                  <div className="d-flex justify-content-center py-2 border-bottom border-gray-500">
                                    <div className="text-center px-2">
                                      {match?.match_datetime === null
                                        ? "TBD"
                                        : moment(match?.match_datetime).format(
                                            "D MMM YYYY, hh:mm A"
                                          )}
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex align-items-center ${
                                      isAWinner
                                        ? "bg-warning text-dark"
                                        : isDraw
                                        ? "bg-gray-800"
                                        : ""
                                    }`}
                                  >
                                    <div className="flex-grow-1 p-3 text-truncate">
                                      {match.team_a.team_name}
                                    </div>
                                    <div className="fs-3 fw-bolder p-3 text-end min-w-60px">
                                      {match.team_a.team_score}
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex align-items-center ${
                                      isBWinner
                                        ? "bg-warning text-dark"
                                        : isDraw
                                        ? "bg-gray-800"
                                        : ""
                                    }`}
                                  >
                                    <div className="flex-grow-1 p-3 text-truncate">
                                      {match.team_b.team_name}
                                    </div>
                                    <div className="fs-3 fw-bolder p-3 text-end min-w-60px">
                                      {match.team_b.team_score}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div>
                        {/* Custom Matches */}
                        {group.matches[0].filter(
                          (x: any) => x.match_title !== null
                        ).length > 0 && (
                          <div className="mt-2 text-white">
                            <div className="fs-5 d-flex align-items-center">
                              <FaGamepad className="me-2" />
                              <div>Custom Matches</div>
                            </div>
                            <div className="mt-2 row mx-0">
                              {group.matches[0]
                                .filter((x: any) => x.match_title !== null)
                                .map((match: any, matchIndex: any) => {
                                  const isAWinner =
                                    match.team_a.team_score >
                                    match.team_b.team_score;
                                  const isBWinner =
                                    match.team_b.team_score >
                                    match.team_a.team_score;
                                  const isDraw =
                                    match.match_status === "ended" &&
                                    match.team_a.team_score ===
                                      match.team_b.team_score;

                                  return (
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                                      {/* <div className="d-flex justify-content-center">
                                        <div className="bg-danger px-2 py-1 rounded-top">
                                          {match?.match_title}
                                        </div>
                                      </div> */}
                                      <div className="rounded border border-gray-500  overflow-hidden">
                                        <div className="d-flex justify-content-center py-2 border-bottom border-gray-500">
                                          <div>
                                            {match?.match_datetime === null
                                              ? "TBD"
                                              : moment(
                                                  match?.match_datetime
                                                ).format("D MMM YYYY, hh:mm A")}
                                          </div>
                                        </div>
                                        <div
                                          className={`p-1 d-flex align-items-center row ${
                                            isAWinner
                                              ? "bg-success"
                                              : isDraw
                                              ? "bg-gray-800"
                                              : ""
                                          }`}
                                        >
                                          <div className="col-9 text-truncate">
                                            <div className="p-2">
                                              {match.team_a.team_name}
                                            </div>
                                          </div>
                                          <div className="text-center fs-3 col-3 fw-bolder">
                                            <div className="p-2">
                                              {match.team_a.team_score}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={`p-1 d-flex align-items-center row ${
                                            isBWinner
                                              ? "bg-success"
                                              : isDraw
                                              ? "bg-gray-800"
                                              : ""
                                          }`}
                                        >
                                          <div className="col-9 text-truncate">
                                            <div className="p-2">
                                              {match.team_b.team_name}
                                            </div>
                                          </div>
                                          <div className="text-center fs-3 col-3 fw-bolder">
                                            <div className="p-2">
                                              {match.team_b.team_score}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-start mt-2 fw-bolder fst-italic">
                                        <div>*{match?.match_title}</div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Tabs.TabPane>
                </Tabs>

                {groupIndex !== group.length - 1 && (
                  <div className="my-8 border-gray-500 border-bottom"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RoundRobinBracket;
