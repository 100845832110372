/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, lazy, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage } from "../modules/auth";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { RootState } from "../../setup";
import { MasterInit } from "../../_metronic/layout/MasterInit";
import { mstAuth, mstGeneral } from "../mobx";
import { getSnapshot } from "mobx-state-tree";
import BracketComponent from "../pages/tournaments/Bracket";
import { PubNubProvider } from "pubnub-react";
import Pubnub from "pubnub";
import PublicLandingPage from "../pages/landing";

const Routes: FC = () => {
  const isAuthorized = getSnapshot(mstAuth)?.isLoggedIn; // useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  // const pubnub = new Pubnub({
  //   publishKey: 'pub-c-0903c9da-7a54-46ce-8995-88432ee3ce56', // process.env.PUBNUB_PUB_KEY as string,
  //   subscribeKey: 'sub-c-b5000013-7560-425c-ad9f-e32a06dea56b', // process.env.PUBNUB_SUB_KEY as string,
  //   uuid: `${getSnapshot(mstAuth).userDetail.user_uuid}`
  // });

  // useEffect(() => {
  //   pubnub.subscribe({ channels: getSnapshot(mstGeneral).GLOBAL_CHANNEL_CHAT, withPresence: true });
  // }, [pubnub])

  // useEffect(() => {
  //   var message = "Test notification"
  //   if (!("Notification" in window)) {
  //     alert("This browser does not support system notifications");
  //   }
  //   else if (Notification.permission === "granted") {
  //     if(typeof message === 'string'){
  //       var notification = new Notification(message);
  //     } else{
  //       var notification = new Notification("Hello World");
  //     }
  //   }
  //   else if (Notification.permission !== 'denied') {
  //     Notification.requestPermission(function (permission) {
  //       if (permission === "granted") {
  //         var notification = new Notification("Hello World");
  //       }
  //     });
  //   }
  // }, [])

  return (
    <>
      <Switch>
        {/* Public bracket view */}
        <Route path="/bracket/:id" component={BracketComponent} />

        {/* Admin routes */}
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/admin/dashboard" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          // <PubNubProvider client={pubnub}>
          <MasterLayout>
            <PrivateRoutes /> {/* Contains TournamentBracketPage */}
          </MasterLayout>
          // </PubNubProvider>
        )}
      </Switch>
      <MasterInit />
    </>
  );
};

export { Routes };
