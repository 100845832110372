import { types, flow, getSnapshot } from "mobx-state-tree";
import axios from "axios";
import persist from "mst-persist";
import { message } from "antd";
import { toast } from "react-toastify";
import moment from "moment";
import * as XLSX from "xlsx";
import { TeamRegistration } from "../../types/types";

// Add this instead
const BASE_URL = process.env.REACT_APP_API_URL;

// Optional: Add environment check
const ENV = process.env.REACT_APP_ENV || "development";
console.log(`Running in ${ENV} environment`);

const TIMEGAP_LIST = [15, 30, 45, 60];

const USER_STATUSES = [
  { id: 1, value: "active", label: "Active" },
  { id: 2, value: "inactive", label: "Inactive" },
];

const TOURNAMENT_STATUSES = [
  { value: "upcoming", label: "Upcoming" },
  { value: "open", label: "Opened" },
  { value: "drafted", label: "Drafted" },
  { value: "on-going", label: "On-going" },
  { value: "closed", label: "Closed" },
  { value: "archived", label: "Archived" },
];

const TOURNAMENT_STAGE_FORMAT = [
  { id: 1, slug: "round-robin", name: "Round Robin" },
  { id: 2, slug: "single-elimination", name: "Single Elimination" },
  // { id: 3, slug: "double-elimination", name: "Double Elimination" },
];

const TOURNAMENT_STAGE_STATUSES = [
  { id: 1, slug: "started", name: "Started" },
  { id: 2, slug: "ongoing", name: "On-Going" },
  { id: 3, slug: "ended", name: "Ended" },
  { id: 4, slug: "draft", name: "Draft" },
];

const GAME_TYPES = [
  { id: 1, value: "moba", label: "MOBA" },
  { id: 2, value: "fps", label: "FPS" },
  { id: 2, value: "battle-royale", label: "Battle Royale" },
];

const GAME_PLATFORMS = [
  { id: 1, value: "mobile", label: "Mobile" },
  { id: 2, value: "pc", label: "PC" },
  { id: 3, value: "console", label: "Console" },
  { id: 4, value: "web", label: "Web" },
];

const GLOBAL_CHANNEL_CHAT = ["todak-arena-global-chat"];

const Notification = types
  .model({
    isTriggered: types.optional(types.boolean, false),
    messageNotification: types.optional(types.map(types.frozen()), {}),
  })
  .actions((self) => {
    const onTriggerNotification = flow(function* () {
      self.isTriggered = !self.isTriggered;
    });

    const getMessageNotification = flow(function* (messageObj) {
      self.messageNotification = messageObj;
    });

    return {
      onTriggerNotification,
      getMessageNotification,
    };
  });
export const mstNotification = Notification.create({});

const General = types
  .model({
    USER_STATUSES: types.optional(types.array(types.frozen()), USER_STATUSES),
    TOURNAMENT_STATUSES: types.optional(
      types.array(types.frozen()),
      TOURNAMENT_STATUSES
    ),
    TOURNAMENT_STAGE_FORMAT: types.optional(
      types.array(types.frozen()),
      TOURNAMENT_STAGE_FORMAT
    ),
    TOURNAMENT_STAGE_STATUSES: types.optional(
      types.array(types.frozen()),
      TOURNAMENT_STAGE_STATUSES
    ),
    GAME_TYPES: types.optional(types.array(types.frozen()), GAME_TYPES),
    GAME_PLATFORMS: types.optional(types.array(types.frozen()), GAME_PLATFORMS),
    GLOBAL_CHANNEL_CHAT: types.optional(
      types.array(types.frozen()),
      GLOBAL_CHANNEL_CHAT
    ),
    TIMEGAP_LIST: types.optional(types.array(types.frozen()), TIMEGAP_LIST),
    active_users: types.optional(types.array(types.frozen()), []),
    chat_details: types.optional(types.array(types.frozen()), []),
    chat_unread_counts: types.optional(types.array(types.frozen()), []),
    selected_chat_user: types.optional(types.map(types.frozen()), {}),
  })
  .actions((self) => {
    const updateSelectedChatUser = (data: any) => {
      self.selected_chat_user = data;
    };

    const updateActiveUsers = (data: any) => {
      self.active_users = data;
    };

    const updateChatDetails = (data: any) => {
      self.chat_details = data;
    };

    const updateChatUnreadCounts = (data: any) => {
      self.chat_unread_counts = data;
    };

    const getRoleBadgeColor = (roleName: any) => {
      if (roleName === "super-admin") {
        return "success";
      } else if (roleName === "admin") {
        return "warning";
      } else if (roleName === "staff") {
        return "danger";
      } else if (roleName === "user") {
        return "primary";
      } else if (roleName === "manager") {
        return "info";
      } else if (roleName === "player") {
        return "dark";
      } else {
        return "default";
      }
    };

    const getStatusColor = (statusName: any) => {
      if (statusName === "upcoming") {
        return "warning";
      } else if (
        statusName === "open" ||
        statusName === "started" ||
        statusName === "active"
      ) {
        return "success";
      } else if (statusName === "drafted" || statusName === "inactive") {
        return "danger";
      } else if (statusName === "on-going" || statusName === "ongoing") {
        return "primary";
      } else if (statusName === "closed" || statusName === "ended") {
        return "info";
      } else if (statusName === "archived") {
        return "dark";
      } else if (statusName === "draft" || statusName === "inactive") {
        return "gray-800"; //bootstrap colors
      } else {
        return "light";
      }
    };

    return {
      updateSelectedChatUser,
      updateActiveUsers,
      updateChatDetails,
      updateChatUnreadCounts,
      getRoleBadgeColor,
      getStatusColor,
    };
  });
export const mstGeneral = General.create({});

const Auth = types
  .model({
    isLoggedIn: types.optional(types.boolean, false),
    bearerToken: types.optional(types.string, ""),
    userDetail: types.optional(types.map(types.frozen()), {}),
    userPermissions: types.optional(types.array(types.frozen()), []),
    tournamentUuid: types.optional(types.string, ""),
  })
  .actions((self) => {
    // AUTH API
    const adminLoginSendOtp = flow(function* (params) {
      const url = BASE_URL + "login";
      try {
        const response = yield axios.post(url, { ...params, platform: "web" });
        console.log(response.data);
        if (response.data.message) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(false);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });
    const adminLoginOtpVerify = flow(function* (params) {
      const url = BASE_URL + "login/otp";
      try {
        const response = yield axios.post(url, params);
        console.log(response.data);
        if (response.data.message.token) {
          self.isLoggedIn = true;
          self.bearerToken = response.data.message.token;
          return Promise.resolve(response.data.message);
        } else {
          self.isLoggedIn = false;
          self.bearerToken = "";
          return Promise.reject(false);
        }
      } catch (err) {
        self.isLoggedIn = false;
        self.bearerToken = "";
        return Promise.reject(err);
      }
    });

    const logout = flow(function* () {
      try {
        self.isLoggedIn = false;
        self.bearerToken = "";
        self.userDetail.clear();
        self.userPermissions.clear();
        setTimeout(() => {
          return Promise.resolve(true);
        }, 1000);
      } catch (err) {
        return Promise.reject(false);
      }
    });

    // USER ROLE API
    const fetchRoles = flow(function* () {
      const url = BASE_URL + "master/role";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.roles);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchSingleRole = flow(function* (role_name) {
      const url = BASE_URL + "master/role/" + role_name;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("fetchSingleRole", response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.permissions);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const createRole = flow(function* (params) {
      const url = BASE_URL + "master/role/store";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("permissions", params.permissions);
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.message) {
          successNotification("Role has successfully created");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const updateRole = flow(function* (params) {
      const url = BASE_URL + "master/role/update/" + params.name;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("permissions", params.permissions);
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.message) {
          successNotification("Role has successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchPermissions = flow(function* () {
      const url = BASE_URL + "master/permission";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.permissions);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // BRACKET API
    const fetchSingleBracket = flow(function* (params) {
      const url = BASE_URL + "tournament/stage/show/" + params?.id + "/bracket";
      console.log("check url ===> ", url);
      try {
        const response = yield axios.get(url);
        console.log("fetchsinglebracket ===> ", response.data);
        if (response.data.errors === null) {
          if (response.data.message.stage_format === "round-robin") {
            const data = {
              data: response.data.message.grouping.data.stage[0].groups,
              format: response.data.message.stage_format,
              customMatches: response.data.message.custom_matches,
              grouping: response.data.message.grouping,
            };
            return Promise.resolve(data);
          } else {
            const data = {
              data: response.data.message.brackets.matches,
              format: response.data.message.stage_format,
              customMatches: response.data.message.custom_matches,
              grouping: response.data.message.grouping,
            };
            return Promise.resolve(data);
          }
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // TOURNAMENT API
    const fetchTournaments = flow(function* (
      params = { currentPage: 1, keyword: "" }
    ) {
      const url =
        BASE_URL +
        "tournament" +
        "?page=" +
        params.currentPage +
        "&keyword=" +
        params.keyword;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        if (response.data.errors === null) {
          // Return the full message object instead of just tournaments
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // console.log("fetchTournaments ===> ", fetchTournaments);

    const fetchSingleTournament = flow(function* (uuid) {
      const url = BASE_URL + "tournament/show/" + uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const createTournament = flow(function* (params) {
      const url = BASE_URL + "tournament/store";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("game", params.game);
      formData.append("name", params.name);
      formData.append("start_date", params.start_end_date[0]);
      formData.append("end_date", params.start_end_date[1]);
      formData.append("min_player", params.min_player);
      formData.append("max_player", params.max_player);
      formData.append("platform", params.platform);
      formData.append("type", params.type);
      formData.append("status", params.status);
      formData.append("desc", params.desc);
      formData.append("rules", params.rules);
      formData.append("banner", params.banner);
      formData.append("thumbnail", params.thumbnail);
      params.prizes
        ?.filter((x: any) => x !== undefined)
        .map((prize: any, prizeIndex: number) => {
          formData.append(`prizes[${prizeIndex}][prize_name]`, prize.name);
          formData.append(`prizes[${prizeIndex}][prize_value]`, prize.value);
        });
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("Tournament has successfully created");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const editTournament = flow(function* (params) {
      const url = BASE_URL + "tournament/update/" + params.uuid;
      console.log("url ===> ", url);
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("game", params.game);
      formData.append("name", params.name);
      formData.append("start_date", params.start_end_date[0]);
      formData.append("end_date", params.start_end_date[1]);
      formData.append("min_player", params.min_player);
      formData.append("max_player", params.max_player);
      formData.append("platform", params.platform);
      formData.append("type", params.type);
      formData.append("status", params.status);
      formData.append("desc", params.desc);
      formData.append("rules", params.rules);
      if (params.banner !== undefined) {
        formData.append("banner", params.banner);
      }
      if (params.thumbnail !== undefined) {
        formData.append("thumbnail", params.thumbnail);
      }
      params.prizes?.map((prize: any, prizeIndex: number) => {
        formData.append(`prizes[${prizeIndex}][prize_name]`, prize.name);
        formData.append(`prizes[${prizeIndex}][prize_value]`, prize.value);
      });
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("Tournament has successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // TOURNAMENT STAGE API
    const fetchTournamentStages = flow(function* (params) {
      const url = BASE_URL + "tournament/stage/" + params.uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.stages);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const createTournamentStage = flow(function* (params) {
      if (!params.tournament_uuid) {
        return Promise.reject(new Error("Tournament UUID is required"));
      }

      const url = `${BASE_URL}tournament/stage/${
        params.isNextStage ? "next-stage" : "store"
      }/${params.tournament_uuid}`;

      const formData = new FormData();

      // Common required fields
      formData.append("name", params.name);
      formData.append("format", params.format);
      formData.append("status", params.status);
      formData.append("start_date", params.start_date);
      formData.append("end_date", params.end_date);
      formData.append("isNextStage", params.isNextStage.toString());
      formData.append("match_per_day", params.match_per_day);
      formData.append("match_per_time", params.match_per_time);
      formData.append("match_interval", params.match_interval);

      // Only include number_participate for new stages
      if (!params.isNextStage) {
        formData.append(
          "number_participate",
          params.number_participate.toString()
        );
      }

      // Handle team selection for next stage
      if (params.isNextStage) {
        formData.append("selection_type", params.selection_type || "manual");

        // Handle both selectedTeams array and teams_uuid array
        const teamsToAdd = params.selectedTeams || params.teams_uuid || [];

        teamsToAdd.forEach((team: any, index: number) => {
          const teamUuid = typeof team === "string" ? team : team.team_uuid;
          formData.append(`team_uuid[${index}]`, teamUuid);
        });
      }

      // Add group_winners and num_per_group if provided
      if (params.group_winners) {
        formData.append("group_winners", params.group_winners);
      }
      if (params.num_per_group) {
        formData.append("num_per_group", params.num_per_group);
      }

      // Debug log to verify payload
      console.log("FormData contents:");
      for (let pair of Array.from(formData.entries())) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };

      try {
        const response = yield axios.post(url, formData, config);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err: any) {
        console.error("API Error:", err.response?.data);
        if (err.response?.data) {
          return Promise.reject(err.response.data);
        }
        return Promise.reject(err);
      }
    });

    const editTournamentStage = flow(function* (params) {
      const url = BASE_URL + "tournament/stage/update/" + params.uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      const newParams = {
        name: params.name,
        status: params.status,
        start_date: moment(params.start_end_date[0]).format("DD/MM/YYYY HH:mm"),
        end_date: moment(params.start_end_date[1]).format("DD/MM/YYYY HH:mm"),
        match_per_day: params.match_per_day,
        match_per_time: params.match_per_time,
        match_interval: params.match_interval,
      };
      // let formData = new FormData()
      // formData.append('name', params.name);
      // formData.append('format', params.format);
      // formData.append('number_participate', params.number_participate);
      // formData.append('status', params.status);
      // formData.append('start_date', params.start_end_date[0]);
      // formData.append('end_date', params.start_end_date[1]);
      // console.log("check formData editTournamentStage", formData)
      // if (params.format === 'round-robin') {
      // formData.append('num_per_group', params.num_per_group);
      // }
      // formData.append('group_winners', params.group_winners);
      // formData.append('lower_range', params.lower_range);
      try {
        const response = yield axios.post(url, newParams, config);
        console.log("editTournamentStage", response.data.message);
        if (response.data.errors === null) {
          successNotification("Stage has successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const deleteTournamentStage = flow(function* (stageUuid) {
      const url = BASE_URL + "tournament/stage/delete/" + stageUuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.delete(url, config);
        console.log("API Response:", response.data); // Debug log
        if (response.data.errors === null && response.data?.message) {
          return response.data; // Return the whole response
        } else {
          return Promise.reject("Invalid response format");
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const startTournamentStage = flow(function* (stageUuid) {
      const url = BASE_URL + "tournament/stage/start/" + stageUuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.post(url, {}, config);
        console.log("startTournamentStage", response.data.message);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchSingleTournamentStage = flow(function* (params) {
      const url = BASE_URL + "tournament/stage/show/" + params.uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchTeamsByStageGroup = flow(function* (tournamentUuid: string) {
      const url = BASE_URL + "tournament/stage/group/teams/" + tournamentUuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("API Response:", response.data); // Debug log
        if (response.data.errors === null && response.data.message?.stages) {
          return response.data; // Return the whole response
        } else {
          return Promise.reject("Invalid response format");
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // TOURNAMENT: STAGE MATCH API
    const fetchMatches = flow(function* (
      params = { currentPage: 1, keyword: "", sortDate: "", status: "" }
    ) {
      const url =
        BASE_URL +
        "tournament/stage/match/" +
        params.uuid +
        `?page=${params.currentPage}` +
        `&keyword=${params.keyword}` +
        `&sortDate=${params.sortDate}` +
        `&status=${params.status}`;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      console.log("url ===> ", url);
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const createCustomMatch = flow(function* (params) {
      const url =
        BASE_URL + "tournament/stage/match/" + params.stage_uuid + "/store";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      const formData = new FormData();
      formData.append("tournament_group_uuid", params.group_uuid);
      formData.append("team_a_uuid", params.team_a);
      formData.append("team_b_uuid", params.team_b);
      formData.append("match_datetime", params.datetime);
      formData.append("match_title", params.title);
      try {
        const response = yield axios.post(url, formData, config);
        console.log("createCustomMatch", response.data.message);
        if (response.data.errors === null) {
          successNotification("Match has successfully created");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const editMatch = flow(function* (formData: FormData) {
      const url =
        BASE_URL + "tournament/stage/match/update/" + formData.get("uuid");
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        const response = yield axios.post(url, formData, config);
        if (response.data.errors === null) {
          successNotification("Match has been successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const deleteMatch = flow(function* (params) {
      const url =
        BASE_URL + "tournament/stage/match/delete/" + params.match_uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      console.log("check url ====> ", url);
      try {
        const response = yield axios.delete(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("Match has successfully deleted");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const deleteMatchImage = flow(function* (uuid: string) {
      const url = BASE_URL + "tournament/stage/match/image/" + uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };

      try {
        const response = yield axios.delete(url, config);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchStageGroups = flow(function* (params) {
      const url = BASE_URL + "tournament/stage/group/" + params.stage_uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.groups);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchTeamsByGroup = flow(function* (params) {
      const url = BASE_URL + "tournament/stage/group/team/" + params.group_uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.teams);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchTeamsByStage = flow(function* (params) {
      const url = BASE_URL + "tournament/stage/teams/" + params.stage_uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // TEAMS API
    // Upload Teams with excel file
    const uploadTeams = flow(function* (params: {
      file: File;
      tournament_uuid: string;
    }) {
      const url = BASE_URL + "upload-team/" + params.tournament_uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      try {
        const formData = new FormData();
        formData.append("team_file", params.file);

        const response = yield axios.post(url, formData, config);

        // If we have data in the response
        if (response.data) {
          // Check if it's a duplicate email error
          if (response.data.data && Array.isArray(response.data.data)) {
            return Promise.reject({
              message: "Email Already Exist",
              data: response.data,
            });
          }

          // Check if it's a success response
          if (
            response.data.message &&
            response.data.message !== "Error Upload"
          ) {
            return Promise.resolve({
              message: "Teams uploaded successfully",
              data: response.data,
            });
          }

          // If we get here, it's probably an error
          return Promise.reject({
            message: response.data.message || "Error uploading teams",
            data: response.data,
          });
        }

        return Promise.reject({
          message: "Invalid response from server",
          data: null,
        });
      } catch (err: any) {
        return Promise.reject({
          message: err.response?.data?.message || "Failed to upload teams",
          data: err.response?.data,
        });
      }
    });

    // Add helper function for XLSX validation
    const readAndValidateXLSX = (file: File): Promise<TeamRegistration[]> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(
              worksheet
            ) as TeamRegistration[];

            // Validate each row
            const errors: string[] = [];
            jsonData.forEach((row, index) => {
              // Required fields validation
              const requiredFields = [
                "nama-pengurus",
                "no-phone",
                "email",
                "sekolah",
                "daerah",
                "nama-pasukan",
                "setuju-terma",
              ];

              requiredFields.forEach((field) => {
                if (!row[field as keyof TeamRegistration]) {
                  errors.push(`Row ${index + 1}: Missing ${field}`);
                }
              });

              // Email validation
              if (row.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(row.email)) {
                errors.push(`Row ${index + 1}: Invalid email format`);
              }

              // Phone number validation
              if (row["no-phone"] && !/^[0-9+]{10,15}$/.test(row["no-phone"])) {
                errors.push(`Row ${index + 1}: Invalid phone number format`);
              }

              // Player validation (must have at least 5 players)
              let playerCount = 0;
              for (let i = 1; i <= 6; i++) {
                if (row[`pemain-${i}-nama` as keyof TeamRegistration])
                  playerCount++;
              }
              if (playerCount < 5) {
                errors.push(`Row ${index + 1}: Must have at least 5 players`);
              }

              // Age validation (13-21 years old)
              for (let i = 1; i <= 6; i++) {
                const age = parseInt(
                  row[`pemain-${i}-umur` as keyof TeamRegistration]
                );
                if (
                  row[`pemain-${i}-nama` as keyof TeamRegistration] &&
                  (!age || age < 13 || age > 21)
                ) {
                  errors.push(
                    `Row ${index + 1}: Player ${i} age must be between 13-21`
                  );
                }
              }
            });

            if (errors.length > 0) {
              reject(new Error(`Validation errors:\n${errors.join("\n")}`));
            } else {
              resolve(jsonData);
            }
          } catch (error) {
            reject(new Error("Error processing XLSX file"));
          }
        };

        reader.onerror = () => {
          reject(new Error("Error reading file"));
        };

        reader.readAsBinaryString(file);
      });
    };

    // FETCH TEAMS
    const fetchTeams = flow(function* (
      params: {
        currentPage: number;
        keyword: string;
        tournament_uuid?: string;
        type?: string;
      } = {
        currentPage: 1,
        keyword: "",
      }
    ) {
      const url =
        BASE_URL +
        "team" +
        "?page=" +
        params.currentPage +
        "&keyword=" +
        params.keyword +
        (params.tournament_uuid
          ? "&tournament_uuid=" + params.tournament_uuid
          : "") +
        (params.type ? "&type=" + params.type : ""); // Add type to the query

      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchSingleTeam = flow(function* (uuid) {
      const url = BASE_URL + "team/show/" + uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // Invite teams to tournament
    const inviteTeamsToTournament = flow(function* (params: {
      tournament_uuid: string;
      team_uuids: string[];
    }) {
      if (!params.tournament_uuid) {
        return Promise.reject(new Error("Tournament UUID is required"));
      }

      const url = BASE_URL + "tournament/team/invite/" + params.tournament_uuid;

      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      // Format the teams_uuid array into the required format
      const formattedData = new FormData();
      params.team_uuids.forEach((uuid) => {
        formattedData.append("team_uuid[]", uuid);
      });

      try {
        const response = yield axios.post(url, formattedData, config);

        if (response.data.errors === null) {
          successNotification("Teams have been successfully invited");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err: any) {
        console.error("Invite error:", err.response?.data || err);
        return Promise.reject(err);
      }
    });

    // Select teams to tournament
    const selectTeamsToTournament = flow(function* (params: {
      tournament_uuid: string;
      team_uuids: string[];
    }) {
      if (!params.tournament_uuid) {
        return Promise.reject(new Error("Tournament UUID is required"));
      }

      const url = BASE_URL + "tournament/select/team/" + params.tournament_uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      // Format the teams_uuid array into the required format
      const formattedData = new FormData();
      params.team_uuids.forEach((uuid, index) => {
        formattedData.append("teams_uuid[]", uuid);
      });

      try {
        const response = yield axios.post(url, formattedData, config);

        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject({
            message: response.data.message,
            errors: response.data.errors,
          });
        }
      } catch (err: any) {
        if (err.response?.data) {
          return Promise.reject({
            message: err.response.data.message,
            errors: err.response.data.errors,
          });
        }
        return Promise.reject(err);
      }
    });

    // remove teams from tournament
    const removeTeamsFromTournament = flow(function* (params: {
      tournament_uuid: string;
      team_uuids: string[];
    }): Generator<any, Promise<any>, any> {
      if (!params.tournament_uuid) {
        return Promise.reject(new Error("Tournament UUID is required"));
      }

      const url = BASE_URL + "tournament/remove/team/" + params.tournament_uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      // Format the teams_uuid array into the required format
      const formattedData = new FormData();
      params.team_uuids.forEach((uuid, index) => {
        formattedData.append("teams_uuid[]", uuid);
      });

      try {
        const response = yield axios.post(url, formattedData, config);

        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject({
            message: response.data.message,
            errors: response.data.errors,
          });
        }
      } catch (err: any) {
        if (err.response?.data) {
          return Promise.reject({
            message: err.response.data.message,
            errors: err.response.data.errors,
          });
        }
        return Promise.reject(err);
      }
    });

    // CHAT API
    const fetchOrCreateChannel = flow(function* (params) {
      const url = BASE_URL + "user/chat/chat-channel/" + params.recipient_id;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("check response fetchOrCreateChannel ===> ", response);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchChatList = flow(function* () {
      const url = BASE_URL + "user/chat";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("check response fetchChatList ===> ", response);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.chats);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const notifyChat = flow(function* (params) {
      const url = BASE_URL + "user/chat/chat-notify";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.post(url, params, config);
        console.log("notifyChat", response.data, params);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // USER API
    const fetchUsers = flow(function* (
      params = { currentPage: 1, keyword: "" }
    ) {
      const url =
        BASE_URL +
        "user" +
        "?page=" +
        params.currentPage +
        "&keyword=" +
        params.keyword;
      console.log("check fetchusers params ====> ", url);
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("check response fetchUsers ===> ", response);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchUserByRole = flow(function* (params) {
      const url = BASE_URL + "user/get-manager/" + params.role;
      console.log("check fetchUserByRole params ====> ", url);
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("check response fetchUserByRole ===> ", response);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchDeletedUsers = flow(function* () {
      const url = BASE_URL + "user/delete/userDeleted";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("check response fetchDeletedUsers ===> ", response);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchDeletionUsers = flow(function* () {
      const url = BASE_URL + "user/delete";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("check response fetchDeletionUsers ===> ", response);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const setUserDeleteStatus = flow(function* (params) {
      const url = BASE_URL + "user/delete/set/" + params.uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("status", params.status);
      if (params.notes) {
        formData.append("notes", params.notes);
      }
      console.log("check setUserDeleteStatus params ===> ", params);
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("User deletion status has successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const fetchSingleUser = flow(function* (uuid) {
      const url = BASE_URL + "user/show/" + uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const createUser = flow(function* (params) {
      const url = BASE_URL + "user/store";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("first_name", params.first_name);
      formData.append("last_name", params.last_name);
      formData.append("status", params.status);
      formData.append("dob", params.dob);
      formData.append("username", params.username);
      formData.append("email", params.email);
      formData.append("phone_number", params.phone_number);
      formData.append("country_uuid", params.country_uuid);
      formData.append("role", params.role);
      formData.append("profile_photo", params.profile_photo);
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("User has successfully created");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const editUser = flow(function* (params) {
      const url = BASE_URL + "user/update";
      console.log("url ===> ", url, params);
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("first_name", params.first_name);
      formData.append("last_name", params.last_name);
      formData.append("status", params.status);
      formData.append("dob", params.dob);
      formData.append("username", params.username);
      formData.append("email", params.email);
      formData.append("phone_number", params.phone_number);
      formData.append("country_uuid", params.country_uuid);
      formData.append("role", params.role);
      formData.append("uuid", params.uuid);
      formData.append("profile_photo", params.profile_photo);
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("User has successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // COUNTRY API
    const fetchCountries = flow(function* () {
      const url = BASE_URL + "master/country";
      try {
        const response = yield axios.get(url);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.countries);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // GAME API
    const fetchGameList = flow(function* (
      params = { currentPage: 1, keyword: "" }
    ) {
      const url =
        BASE_URL +
        "master/game/exclude" +
        "?page=" +
        params.currentPage +
        "&keyword=" +
        params.keyword;
      try {
        const response = yield axios.get(url);
        console.log(response.data);
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message.games);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const createGame = flow(function* (params) {
      const url = BASE_URL + "master/game/store";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("platform", params.platform);
      formData.append("type", params.type);
      formData.append(`logo`, params.logo);
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("Game has successfully created");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const updateGame = flow(function* (params) {
      const url = BASE_URL + "master/game/update/" + params.uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      let formData = new FormData();
      formData.append("name", params.name);
      formData.append("platform", params.platform);
      formData.append("type", params.type);
      if (params.logo !== undefined) {
        formData.append("logo", params.logo);
      }
      try {
        const response = yield axios.post(url, formData, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("Game has successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const deleteGame = flow(function* (params) {
      const url = BASE_URL + "master/game/delete/" + params.uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.delete(url, config);
        console.log(response.data);
        if (response.data.errors === null) {
          successNotification("Game has successfully deleted");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // DASHBOARD API
    const fetchDashboardItems = flow(function* () {
      const url = BASE_URL + "dashboard";
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        if (response.data.data) {
          console.log(response.data.data);
          return Promise.resolve(response.data.data);
        } else {
          return Promise.reject(response.data.errors);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    // SETTING API
    const fetchPolicy = flow(function* (params) {
      const url = BASE_URL + "configuration/show?type=" + params.type;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      try {
        const response = yield axios.get(url, config);
        console.log("mobx fetchPolicy ===> ", response);
        // if (response.data.responseStatus === 403) {
        // message.error("Permission denied")
        // } else {
        if (response.data.errors === null) {
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
        // }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const updatePolicy = flow(function* (params) {
      const url = BASE_URL + "configuration/update/" + params.uuid;
      const config = {
        headers: {
          Authorization: `Bearer ${self.bearerToken}`,
        },
      };
      const formData = new FormData();
      formData.append("content", params.content);
      try {
        const response = yield axios.post(url, formData, config);
        // if (response.data.responseStatus === 403) {
        // message.error("Permission denied")
        // } else {
        if (response.data.errors === null) {
          successNotification("Policy has successfully updated");
          return Promise.resolve(response.data.message);
        } else {
          return Promise.reject(response.data.errors);
        }
        // }
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const updateCurrentUserDetail = flow(function* (dataObj) {
      self.userDetail = dataObj;
      setTimeout(() => {
        return Promise.resolve(true);
      }, 1000);
    });

    const updateCurrentUserPermissions = flow(function* (dataArray) {
      self.userPermissions = dataArray;
      setTimeout(() => {
        return Promise.resolve(true);
      }, 1000);
    });

    const successNotification = (message: any) => {
      toast.success(message, {
        theme: "colored",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    const errorNotification = (message: any, fallbackFunction: Function) => {
      toast.error(message, {
        theme: "colored",
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          fallbackFunction();
        },
        onClick: () => {
          fallbackFunction();
        },
      });
    };

    return {
      adminLoginSendOtp,
      adminLoginOtpVerify,
      logout,
      createUser,
      editUser,
      fetchTournaments,
      fetchTournamentStages,
      editTournamentStage,
      fetchSingleTournamentStage,
      fetchMatches,
      createCustomMatch,
      editMatch,
      createTournamentStage,
      deleteTournamentStage,
      startTournamentStage,
      fetchSingleBracket,
      fetchSingleTournament,
      fetchSingleUser,
      createTournament,
      editTournament,
      uploadTeams,
      fetchTeams,
      fetchSingleTeam,
      inviteTeamsToTournament,
      deleteMatch,
      deleteMatchImage,
      fetchTeamsByStageGroup,
      fetchTeamsByGroup,
      fetchTeamsByStage,
      selectTeamsToTournament,
      removeTeamsFromTournament,
      fetchStageGroups,
      fetchOrCreateChannel,
      fetchChatList,
      notifyChat,
      fetchUsers,
      fetchUserByRole,
      fetchDeletedUsers,
      fetchDeletionUsers,
      setUserDeleteStatus,
      fetchGameList,
      createGame,
      updateGame,
      deleteGame,
      fetchCountries,
      fetchRoles,
      fetchSingleRole,
      fetchPermissions,
      updateRole,
      createRole,
      updateCurrentUserDetail,
      updateCurrentUserPermissions,
      fetchDashboardItems,
      fetchPolicy,
      updatePolicy,
      successNotification,
      errorNotification,
    };
  });

export const mstAuth = Auth.create({});

if (typeof window !== "undefined") {
  persist("mstAuth", mstAuth, { storage: window.localStorage, jsonify: true });
}
