import React, { useEffect } from "react";
import ReactDOM from "react-dom";
// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import * as _redux from "./setup";
import store, { persistor } from "./setup/redux/Store";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";

// Apps
import { App } from "./app/App";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import { mstAuth } from "./app/mobx";
import { message } from "antd";
import { getSnapshot } from "mobx-state-tree";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

Chart.register(...registerables);

axios.interceptors.response.use(
  function (response: any) {
    if (response.responseStatus === 403) {
      mstAuth.errorNotification("Permission Denied", () => {
        window.location.replace("/admin/dashboard");
      });
      // message.error("Permission Denied")
    } else {
      return response;
    }
  },
  function (error) {
    console.log(error.response.data);
    if (error.response.status === 401) {
      mstAuth.errorNotification("Session Expired. Please login again", () => {
        mstAuth.logout().then((response) => {
          window.location.replace("/admin/auth");
        });
      });
    }
    return Promise.reject(error);
  }
);

/* Disable console.log on production */
// console.log("ccheck process.env.node_env ===> ", process.env.NODE_ENV)

// if (process.env.NODE_ENV !== "development") {
console.log = () => {};
// }

ReactDOM.render(
  <MetronicI18nProvider>
    <ToastContainer />
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
