/* eslint-disable jsx-a11y/anchor-is-valid */
import {createRef, FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../helpers'
import { getSnapshot } from 'mobx-state-tree'
import { mstAuth } from '../../../app/mobx'
import NotificationSound from "../../../app/modules/apps/chat/new-message-sound.mp3";
import moment from 'moment'
import { Checkbox } from 'antd'

interface ISelectedUser {
  uuid?: String
  name?: String
  channel_uuid?: String
}

interface IMessage {
  message?: String
  recipient?: {
    uuid?: String,
    name?: String
  },
  sender?: {
    uuid?: String,
    name?: String
  },
  timestamp?: any
}

type Props = {
  messages?: Array<[IMessage]>
  selectedUser?: ISelectedUser
  selectedMessages?: Array<[]>
  isDrawer?: boolean
  onSendMessage?: Function
  loadingMessage?: boolean
  onUpdateSelectedMessages?: Function
  onDeleteMessages?: Function
  onDeleteAllMessages?: Function
}

const bufferMessages = defaultMessages

const ChatInner: FC<Props> = ({ messages = [], isDrawer = false, selectedUser = {}, selectedMessages = [], onUpdateSelectedMessages = undefined, onDeleteMessages = undefined, onDeleteAllMessages = undefined, onSendMessage = undefined, loadingMessage = true }) => {
  // console.log("chahahdhsadhashdash ===> ", messages, selectedUser)
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [newMessage, setNewMessage] = useState<string>('')
  const [newMessages, setNewMessages] = useState<MessageModel[]>(bufferMessages)
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [messageRefs, setMessageRefs] = useState([])
  const bottomRef = useRef<HTMLDivElement>(null);

  const sendMessage = () => {
    if (onSendMessage !== undefined) {
      onSendMessage({ 
        message: newMessage,
        recipient: {
          name: selectedUser?.name,
          uuid: selectedUser?.uuid
        },
        sender: {
          name: getSnapshot(mstAuth).userDetail.username,
          uuid: getSnapshot(mstAuth).userDetail.user_uuid
        },
        channel_uuid: `private.${selectedUser.channel_uuid}`, 
        timestamp: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      })
    }
    const ref: any = bottomRef.current
    if (ref) {
      ref.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
    setNewMessage("")
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const toggleSelectMessage = (timetoken: any) => {
    const oldSelectedMessages: any = selectedMessages.slice()
    const foundItem: any = oldSelectedMessages.find((x: any) => x === timetoken)
    if (foundItem) {
      if (onUpdateSelectedMessages !== undefined) {
        onUpdateSelectedMessages(oldSelectedMessages.filter((x: any) => x !== foundItem))
      }
      console.log("ITEM FOUND ===> ", oldSelectedMessages)
    } else {
      oldSelectedMessages.push(timetoken)
      console.log("ITEM NOT FOUND ===> ", oldSelectedMessages)
      if (onUpdateSelectedMessages !== undefined) {
        onUpdateSelectedMessages(oldSelectedMessages)
      }
    }
  }

  const deleteMessages = () => {
    if (onDeleteMessages !== undefined) {
      onDeleteMessages(selectedUser.channel_uuid, selectedMessages)
    } 
  }

  const deleteAllMessages = () => {
    if (onDeleteAllMessages !== undefined) {
      onDeleteAllMessages()
    }
  }
  
  return (
    <div
      className='card-body pb-0'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className='h-min-500px h-500px overflow-scroll pe-5 pb-0'
        // className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {
          loadingMessage ?
          (
            <div className="text-center h-100 d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {/* <div className="mt-4">Loading Chat</div> */}
            </div>
          )
          :
          (
            <>
              {
                messages.map((message: any, index: Number) => {
                  // const userInfo = userInfos[newMessage.user]
                  const state = message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'info' : 'primary'
                  const templateAttr = {}
                  if (true) {
                    Object.defineProperty(templateAttr, 'data-kt-element', {
                      value: `template-${message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'in' : 'out'}`,
                    })
                  }
                  const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                    message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'start' : 'end'
                  } mb-10`
                  return (
                    <div
                      key={`message${index}`}
                      className={clsx('d-flex', contentClass, 'mb-10', {'d-none': ""})}
                      {...templateAttr}
                    >
                      <div
                        className={clsx(
                          'd-flex flex-column align-items',
                          `align-items-${message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'start' : 'end'}`
                        )}
                      >
                        <div className='d-flex align-items-center mb-2'>
                          {message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? (
                            <>
                              <div className='symbol  symbol-35px symbol-circle '>
                                <img alt='Pic' src={toAbsoluteUrl(`https://ui-avatars.com/api/?name=${message.sender.name}`)} />
                              </div>
                              <div className='ms-3'>
                                <a
                                  href='#'
                                  className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                                >
                                  {message.sender.name}
                                </a>
                                <span className='text-muted fs-7 mb-1'>{message.timestamp}</span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='me-3'>
                                {/* <Checkbox className='btn btn-default' onClick={() => { toggleSelectMessage(message.timetoken) }} /> */}
                                <span className='text-muted fs-7 mb-1'>{message.timestamp}</span>
                                <a
                                  href='#'
                                  className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                                >
                                  You
                                </a>
                              </div>
                              <div className='symbol symbol-35px symbol-circle '>
                              <img alt='Pic' src={toAbsoluteUrl(`https://ui-avatars.com/api/?name=${message.sender.name}`)} />
                              </div>
                            </>
                          )}
                        </div>

                        <div
                          className={clsx(
                            'p-5 rounded',
                            `bg-light-${state}`,
                            'text-dark fw-bold mw-lg-400px',
                            `text-${message.sender.uuid !== getSnapshot(mstAuth).userDetail.user_uuid ? 'start' : 'end'}`
                          )}
                          data-kt-element='message-text'
                          dangerouslySetInnerHTML={{__html: message.message}}
                        ></div>
                      </div>
                    </div>
                  )
                })
              }
            </>
          )
        }
        <div className='mt-10' ref={bottomRef}></div>
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      > 
       {
         false ?
         (
            <>
              <button onClick={() => { deleteMessages() }} className='btn btn-primary me-2'>Delete Selected Messages</button>
              {/* <button onClick={() => { onUpdateSelectedMessages ? onUpdateSelectedMessages([]) : null }} className='btn btn-primary'>Unselect All</button> */}
              <button onClick={() => { deleteAllMessages() }} className='btn btn-primary'>Delete All</button>
            </>
         )
         :
         (
           <>
            <textarea
              className='form-control form-control-flush mb-3'
              rows={1}
              data-kt-element='input'
              placeholder='Type a Message'
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={onEnterPress}
            ></textarea>

            <div className='d-flex flex-stack justify-content-end'>
              {/* <div className='d-flex align-items-center me-2'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary me-1'
                  type='button'
                  data-bs-toggle='tooltip'
                  title='Coming soon'
                >
                  <i className='bi bi-paperclip fs-3'></i>
                </button>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary me-1'
                  type='button'
                  data-bs-toggle='tooltip'
                  title='Coming soon'
                >
                  <i className='bi bi-upload fs-3'></i>
                </button>
              </div> */}
              <button
                className='btn btn-primary'
                type='button'
                data-kt-element='send'
                onClick={sendMessage}
              >
                Send
              </button>
            </div>
           </>
         )
       }
      </div>
    </div>
  )
}

export {ChatInner}
