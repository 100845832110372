/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

export interface PageLink {
  title: string;
  path: string;
  isActive: boolean;
  isSeparator?: boolean;
}

export interface PageDataContextModel {
  pageTitle?: string;
  setPageTitle: (_title: string) => void;
  pageExtraHeader?: string;
  setPageExtraHeader: (_extraHeader: string) => void;
  pageDescription?: string;
  setPageDescription: (_description: string) => void;
  pageBreadcrumbs?: Array<PageLink>;
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
  pageChildNav?: Boolean;
  setPageChildNav: (_pageChildNav: Boolean) => void;
  // pageExtraHeader?: ReactNode
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageChildNav: (_childNav: Boolean) => {},
  setPageExtraHeader: (_extraHeader: string) => {},
});

const PageDataProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageDescription, setPageDescription] = useState<string>("");
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
  const [pageChildNav, setPageChildNav] = useState<Boolean>(false);
  const [pageExtraHeader, setPageExtraHeader] = useState<string>("");
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageExtraHeader,
    setPageExtraHeader,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageChildNav,
    setPageChildNav,
  };
  return (
    <PageDataContext.Provider value={value}>
      {children}
    </PageDataContext.Provider>
  );
};

function usePageData() {
  return useContext(PageDataContext);
}

type Props = {
  description?: string;
  childNav?: Boolean;
  breadcrumbs?: Array<PageLink>;
};

const PageTitle: FC<Props> = ({
  children,
  description,
  breadcrumbs,
  childNav,
}) => {
  const {
    setPageTitle,
    setPageExtraHeader,
    setPageDescription,
    setPageBreadcrumbs,
    setPageChildNav,
  } = usePageData();
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString());
    }
    return () => {
      setPageTitle("");
    };
  }, [children]);

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }
    return () => {
      setPageDescription("");
    };
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => {
      setPageBreadcrumbs([]);
    };
  }, [breadcrumbs]);

  useEffect(() => {
    if (childNav) {
      setPageChildNav(childNav);
    }
    return () => {
      setPageChildNav(false);
    };
  }, [childNav]);

  return <></>;
};

const PageDescription: React.FC = ({ children }) => {
  const { setPageDescription } = usePageData();
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }
    return () => {
      setPageDescription("");
    };
  }, [children]);
  return <></>;
};

const PageExtraHeader: React.FC = ({ children }) => {
  const { setPageExtraHeader } = usePageData();
  useEffect(() => {
    if (children) {
      setPageExtraHeader(children.toString());
    }
    return () => {
      setPageExtraHeader("");
    };
  }, [children]);
  return <></>;
};

export {
  PageDescription,
  PageTitle,
  PageExtraHeader,
  PageDataProvider,
  usePageData,
};
