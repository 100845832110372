/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {toAbsoluteUrl} from '../../../helpers'
import { getHeapSnapshot } from 'v8'
import { mstAuth, mstGeneral } from '../../../../app/mobx'
import { getSnapshot } from 'mobx-state-tree'
import { usePubNub } from 'pubnub-react'

const HeaderUserMenu: FC = () => {
  const pubnub = usePubNub()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    mstAuth.logout()
    .then((response) => {
      pubnub.unsubscribeAll()
      window.location.reload()
      console.log("logout success")
    })
    .catch((error) => {
      //
    })
  }

  useEffect(() => {
    if (getSnapshot(mstAuth).userDetail?.username === undefined) {
      mstAuth.errorNotification(
        "Session Expired. Please login again", 
        () => {
          mstAuth.logout()
          .then((response) => {
            window.location.replace("/admin/auth")
          })
        }
      )
    }
  }, [getSnapshot(mstAuth).userDetail?.username])
  
  useEffect(() => {
    // console.log("check getsnapshot userDetails headermenu ===> ", getSnapshot(mstAuth).userDetail)
    // console.log("check getsnapshot userPermissions headermenu ===> ", getSnapshot(mstAuth).userPermissions)
  }, [])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl(`${getSnapshot(mstAuth).userDetail?.detail?.profile_image_path !== "" ? getSnapshot(mstAuth).userDetail?.detail?.profile_image_path : `https://ui-avatars.com/api/?name=${getSnapshot(mstAuth).userDetail?.username}`}`)} />
            {/* <img alt='Logo' src={toAbsoluteUrl(getSnapshot(mstAuth).userDetail?.detail?.profile_image_path)} /> */}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {getSnapshot(mstAuth).userDetail?.username !== undefined ? getSnapshot(mstAuth).userDetail?.username : ""}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <div className={`fw-bold fs-8 py-1`}>
              <div className={`badge bg-${mstGeneral.getRoleBadgeColor(getSnapshot(mstAuth).userDetail?.role !== undefined ? getSnapshot(mstAuth).userDetail?.role[0] : "")}`}>
                {getSnapshot(mstAuth).userDetail?.role !== undefined ? getSnapshot(mstAuth).userDetail?.role[0] : ""}
              </div>
            </div>
            <div className='fw-bold text-muted fs-7'>
              {getSnapshot(mstAuth).userDetail?.email !== undefined ? getSnapshot(mstAuth).userDetail?.email : ""}
            </div>
          </div>
        </div>
      </div>

      {/* <div className='separator my-2'></div> */}

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div> */}

      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
